// Chakra imports
import { SimpleGrid, Text, Link, useColorModeValue, Flex, HStack } from '@chakra-ui/react'
// Custom components
import Card from 'components/card/Card.js'
import React, { useEffect } from 'react'
import Information from './Information'
import formatDateDDMMYYYY from 'hooks/formatDateDDMMYYYY'
import { ProjectThumbs } from './projectThumbs'
import axios from 'api/axios'

// Assets
export default function GeneralInformation(props) {
  const { projeto, data, votosFavoraveis, votosContrarios, projetoLeiId, ...rest } = props

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textColorSecondary = 'gray.400'
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')
  return (
    <Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Flex direction="row">
          <Text color={textColorSecondary} fontSize="md" me="10px">
            <ProjectThumbs pontos={'-1'} style={{ marginBottom: 0 }} />
          </Text>
          <Flex direction="column">
            <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
              {projeto}
            </Text>
            <Text color={textColorSecondary} fontSize="md" me="26px">
              {`Data da votação ${formatDateDDMMYYYY(data)}`}
            </Text>
          </Flex>
        </Flex>
        <Information boxShadow={cardShadow} title="Votos Favoráveis" value={votosFavoraveis} w="200px" />
        <Information boxShadow={cardShadow} title="Votos Contrários" value={votosContrarios} w="200px" />
        <Information boxShadow={cardShadow} title="Status" value="Aprovado" w="200px" />
      </Flex>

      <SimpleGrid columns="2" gap="20px"></SimpleGrid>
    </Card>
  )
}
