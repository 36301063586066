import React, { useState, useEffect } from 'react';
import { app } from '../../../../api/feathers';
import { Text, useColorModeValue } from '@chakra-ui/react';

import Parlamentar from 'views/public/partido/components/Parlamentar';
import Card from 'components/card/Card.js';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Partido } from 'views/public/partido';

export default function Projects(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset'
  );

  const { partidoId } = useParams();

  // console.log('params', partidoId);

  const [dados, setData] = useState([]);

  useEffect(() => {
    app
      .service('parlamentar')
      .find({
        query: {
          $limit: 50,
          partido_id: partidoId,
        },
      })
      .then((response) => {
        setData(response.data);
      });
  }, []);

  // console.log('dados', dados);

  return (
    <Card mb={{ base: '0px', '2xl': '20px' }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'
      >
        Parlamentares
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Conheça os parlamentares que representam o partido na Câmara.
      </Text>
      {dados.length > 0 &&
        dados.map((parlamentar) => (
          <Parlamentar
            boxShadow={cardShadow}
            parlamentarId={parlamentar.id}
            foto={parlamentar.foto}
            nome={parlamentar.nome}
            rank={parlamentar.rank}
            email={parlamentar.email}
            mb='20px'
          />
        ))}
    </Card>
  );
}
