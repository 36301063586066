import React, { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import { app } from '../../../api/feathers';

import { Box, SimpleGrid } from '@chakra-ui/react';
// import Card from '../../../components/card/Card';

import Partidos from './components/Partidos';

export default function Settings() {
  const [dados, setData] = useState([]);

  useEffect(() => {
    app
      .service('partido')
      .find({
        query: {
          $limit: 150,
        },
      })
      .then((response) => {
        setData(response.data);
      });
  }, []);

  // console.log('dados', dados);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Partidos
        columnsData={[
          {
            Header: 'Nome',
            accessor: 'nome',
          },
          {
            Header: 'Código',
            accessor: 'numeroEleitoral',
          },
          {
            Header: 'Pontos',
            accessor: 'rank',
          },
        ]}
        tableData={dados}
      />
    </Box>
  );
}
