import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

export default function ProjectThumbs(props) {
  const { pontos, tipo, voto } = props;

  let icon;
  let bgThumb;
  let thumbColor;
  let paddingSize;
  let marginTopSize;
  let marginLeftSize;
  let marginRightSize;

  // console.log(`ProjectThumbs - Ponto: ${pontos}, Voto: ${voto}`);

  if (!voto) {
    switch (pontos) {
      case -2:
        icon = faThumbsDown;
        bgThumb = '#f0d7d7';
        thumbColor = '#845555';
        break;
      case 2:
        icon = faThumbsUp;
        bgThumb = '#d7f0e3';
        thumbColor = '#55846c';
        break;
      default:
        // icon = faFaceThinking;
        icon = faThumbsUp;
        bgThumb = '#f0ebd7';
        thumbColor = '#847655';
        break;
    }
  } else {
    const caso = `${pontos},${voto}`;
    switch (caso) {
      case '-2,favoravel':
      case '2,contra':
        icon = faThumbsDown;
        bgThumb = '#f0d7d7';
        thumbColor = '#845555';
        // console.log(caso);
        break;

      case '2,favoravel':
      case '-2,contra':
        icon = faThumbsUp;
        bgThumb = '#d7f0e3';
        thumbColor = '#55846c';
        break;

      default:
        // icon = faFaceThinking;
        icon = faThumbsUp;
        bgThumb = '#f0ebd7';
        thumbColor = '#847655';
        break;
    }
  }

  switch (tipo) {
    case 'img':
      paddingSize = 5;
      marginTopSize = 42;
      marginLeftSize = -30;
      marginRightSize = 7;
      break;
    default:
      paddingSize = 20;
      marginTopSize = 0;
      marginLeftSize = 0;
      marginRightSize = 10;
      break;
  }

  return (
    <FontAwesomeIcon
      icon={icon}
      style={{
        padding: paddingSize,
        background: bgThumb,
        marginRight: marginRightSize,
        marginLeft: marginLeftSize,
        borderRadius: 50,
        color: thumbColor,
        marginTop: marginTopSize,
      }}
    />
  );
}
