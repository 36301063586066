// Chakra imports
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import React from 'react';
// Assets
import { ProjectPoints } from './projectPoints';
import { ProjectThumbs } from './projectThumbs';

export default function Project(props) {
  const { message, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const brandColor = useColorModeValue('brand.500', 'white');
  const bg = useColorModeValue('white', 'navy.700');
  return (
    <Card bg={bg} {...rest} p='14px'>
      <Flex align='center' direction={{ base: 'column', md: 'row' }}>
        <Box mt={{ base: '10px', md: '0' }}>
          <Text
            color={textColorPrimary}
            fontWeight='500'
            fontSize='md'
            mb='4px'
            p='10px'
          >
            {message}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
}
