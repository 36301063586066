import React, { useState, useEffect } from 'react'
import axios from '../../../api/axios'
import { app } from '../../../api/feathers'

import { Box, FormControl, SimpleGrid, Stack } from '@chakra-ui/react'
import Card from '../../../components/card/Card'
// import ComplexTable from 'views/public/dataTables/components/ComplexTable';
// import { columnsDataComplex } from 'views/public/dataTables/variables/columnsData';
// import tableDataComplex from 'views/public/dataTables/variables/tableDataComplex.json';

import ProjetosLei from './components/ProjetosLei'
import { Select } from '@chakra-ui/react'
import { RiTextWrap } from 'react-icons/ri'
import { getUserAuthenticationRole } from 'hooks/authService'

export default function Settings() {
  const userRole = getUserAuthenticationRole() || ''
  const [dados, setData] = useState([])
  const [temaFilter, setTemaFilter] = useState()
  const [casaLegislativaFilter, setCasaLegislativaFilter] = useState()
  const [impactoFilter, setImpactoFilter] = useState()

  let temas = []

  const query = {
    $limit: 150,
    temas: temaFilter,
    casa_legislativa: 'Câmara Municipal de Goiânia',
    impacto: impactoFilter
  }
  if (userRole !== 'admin') {
    query.status = { $ne: 'Despublicado' }
  }

  console.log('query: ', query)

  useEffect(() => {
    app
      .service('projeto-lei')
      .find({
        query
      })
      .then((response) => {
        setData(response.data)
      })
  }, [temaFilter, casaLegislativaFilter, impactoFilter])

  const handleChangeTemas = (event) => {
    const { name, value } = event.target
    setTemaFilter(value)
    // console.log(`Name: ${name}, value: ${value}`);
  }
  const handleChangeCasaLegislativa = (event) => {
    const { name, value } = event.target
    setCasaLegislativaFilter(value)
    // console.log(`Name: ${name}, value: ${value}`);
  }
  const handleChangeImpacto = (event) => {
    const { name, value } = event.target
    setImpactoFilter(value)
    // console.log(`Name: ${name}, value: ${value}`);
  }

  // useEffect(() => {
  //   async function fetchData() {
  //     const request = await axios.get('/projeto-lei');
  //     setData(request.data);
  //     console.log('request.data', request.data);
  //     return request;
  //   }
  //   fetchData();
  // }, []);

  // console.log('dados', dados);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card direction="column" w="100%" px="20px" mb="20px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <form>
          <Stack direction={'row'}>
            <FormControl>
              <Select
                onChange={handleChangeTemas}
                value={temaFilter}
                placeholder="Filtre por tema"
                name="temas"
              >
                <option value="" key=""></option>
                <option value="Acessibilidade" key="Acessibilidade">
                  Acessibilidade
                </option>
                <option value="Administrativo" key="Administrativo">
                  Administrativo
                </option>
                <option value="Comércio" key="Comércio">
                  Comércio
                </option>
                <option value="Consumidor" key="Consumidor">
                  Consumidor
                </option>
                <option value="Empreendedorismo" key="Empreendedorismo">
                  Empreendedorismo
                </option>
                <option value="Funcionamento Bancário" key="Funcionamento Bancário">
                  Funcionamento Bancário
                </option>
                <option value="Indústria" key="Indústria">
                  Indústria
                </option>
                <option value="Mobilidade" key="Mobilidade">
                  Mobilidade
                </option>
                <option value="Saúde" key="Saúde">
                  Saúde
                </option>
                <option value="Segurança" key="Segurança">
                  Segurança
                </option>
                <option value="Tecnologia e Pesquisa" key="Tecnologia e Pesquisa">
                  Tecnologia e Pesquisa
                </option>
                <option value="Trabalhista" key="Trabalhista">
                  Trabalhista
                </option>
                <option value="Tributário" key="Tributário">
                  Tributário
                </option>
                <option value="Outros" key="Outros">
                  Outros
                </option>
              </Select>
            </FormControl>
            <FormControl>
              <Select
                onChange={handleChangeImpacto}
                name="impacto"
                value={impactoFilter}
                placeholder="Filtre por Impacto"
              >
                <option value="Favorável" key="Favorável">
                  Favorável
                </option>
                <option value="Favorável com ressalvas" key="Favorável com ressalvas">
                  Favorável com ressalvas
                </option>
                <option value="Contra com ressalvas" key="Contra com ressalvas">
                  Contra com ressalvas
                </option>
                <option value="Contrário" key="Contrário">
                  Contrário
                </option>
              </Select>
            </FormControl>
            <FormControl>
              <Select
                onChange={handleChangeCasaLegislativa}
                value={casaLegislativaFilter}
                placeholder="Selecione a Casa Legislativa"
                defaultValue={'Câmara Municipal de Goiânia'}
              >
                {/* <option
                  value='Assembleia Legislativa de Goiás'
                  key='Assembleia Legislativa de Goiás'
                >
                  Assembleia Legislativa de Goiás
                </option> */}

                <option value="Câmara Municipal de Goiânia" key="Câmara Municipal de Goiânia">
                  Câmara Municipal de Goiânia
                </option>
              </Select>
            </FormControl>
            <Box
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              padding="10px 20px"
              borderRadius="100px"
              style={{
                backgroundColor: '#3965ff',
                color: '#ffffff',
                cursor: 'pointer',
                justifySelf: 'center',
                minWidth: '130px'
              }}
              RiTextWrap="no-wrap"
              onClick={() => {
                setCasaLegislativaFilter()
                setTemaFilter()
                setImpactoFilter()
              }}
            >
              Limpar Filtros
            </Box>
          </Stack>
        </form>
      </Card>
      <ProjetosLei
        columnsData={[
          {
            Header: 'Projeto e impacto no empreendedorismo',
            accessor: 'projeto'
          },

          {
            Header: 'Ementa',
            accessor: 'ementa'
          },
          {
            Header: 'Autor',
            accessor: 'autor'
          },
          {
            Header: 'Relator',
            accessor: 'relator'
          },
          {
            Header: 'Status',
            accessor: 'status'
          }
        ]}
        tableData={dados}
      />
    </Box>
  )
}
