function SocialNetworkUsername({ url }) {
  const trimmedUrl = url && url.trim();
  const urlWithoutTrailingSlash = trimmedUrl && trimmedUrl.replace(/\/$/, '');
  const urlParts =
    urlWithoutTrailingSlash && urlWithoutTrailingSlash.split('/');
  const slug = urlParts && urlParts[urlParts.length - 1];
  return `@${slug || ''}`;
}

export default SocialNetworkUsername;
