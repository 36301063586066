import axios from '../api/axios'
import jwt_decode from 'jwt-decode'

export async function loginLocal(email, password) {
  try {
    const response = await axios.post('/authentication', {
      email: email,
      password: password,
      strategy: 'local'
    })

    if (response.data.accessToken) {
      localStorage.setItem('user', JSON.stringify(response.data))
      console.log(`Olá ${response.data.user.nome}!`, response.data)
    }

    setTimeout(() => {
      window.location.href = '/'
    }, 600)
  } catch (error) {
    console.error('verifique os dados de login e senha.', error)
  }
}

export function registerUser(nome, email, password, role) {
  return axios.post('/users', {
    nome,
    email,
    password,
    role
  })
}

export function logout() {
  localStorage.removeItem('user')
  window.location.href = '/'
}

export function getUserAuthentication() {
  return JSON.parse(localStorage.getItem('user'))
}

export function getUserAuthenticationRole() {
  const userToken = JSON.parse(localStorage.getItem('user'))
  const role = userToken?.user?.role
  return role
}

axios.interceptors.request.use(
  (config) => {
    const userToken = getUserAuthentication()
    if (userToken && userToken.accessToken) {
      const decodedToken = jwt_decode(userToken.accessToken)
      const currentTime = Date.now() / 1000 // Tempo atual em segundos

      if (decodedToken.exp < currentTime) {
        // Token expirado
        logout()
        return Promise.reject(new Error('Token expirado'))
      } else {
        config.headers.Authorization = `Bearer ${userToken.accessToken}`
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
