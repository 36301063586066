import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../../../api/axios'
// Chakra imports
import { Box, SimpleGrid } from '@chakra-ui/react'

// Custom components
import General from 'views/public/votacao/components/General'
import ParlamentaresFavoraveis from 'views/public/votacao/components/ParlamentaresFavoraveis'
import ParlamentaresContra from 'views/public/votacao/components/ParlamentaresContra'

// Assets

export default function Overview() {
  const { votacaoId } = useParams()
  const [votacao, setVotacao] = useState([])
  const [votosFavoraveis, setVotosFavor] = useState([])
  const [votosContrarios, setVotosContrarios] = useState([])

  useEffect(() => {
    async function getVotacoes() {
      const request = await axios.get(`/votacoes/${votacaoId}`)
      setVotacao(request.data)
      return request
    }
    getVotacoes()
  }, [votacaoId])

  console.log('Votação: ', votacao)

  const { projeto_lei_id } = votacao

  useEffect(() => {
    if (votacao?.projeto_lei_id) {
      async function getVotosFavoravel() {
        const votosFavoraveisRequest = await axios.get(
          `/voto?populateDados=true&voto=favoravel&projeto_lei_id=${projeto_lei_id}`
        )
        setVotosFavor(votosFavoraveisRequest?.data)
        return votosFavoraveisRequest
      }
      async function getVotosContra() {
        const votosContraRequest = await axios.get(
          `/voto?populateDados=true&voto=contra&projeto_lei_id=${projeto_lei_id}`
        )
        setVotosContrarios(votosContraRequest?.data)
        return votosContraRequest
      }

      getVotosFavoravel()
      getVotosContra()
    }
  }, [projeto_lei_id])

  // useEffect(() => {
  //   if (projeto_lei_id) {

  //   }
  // }, [projeto_lei_id]);

  console.log('votosContrarios: ', votosContrarios)
  console.log('votosFavoraveis: ', votosFavoraveis)

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {/* Main Fields */}

      <General
        pe="20px"
        mb="20px"
        projeto={`${votacao?.projeto_lei_name}`}
        data={votacao?.data}
        votosFavoraveis={votacao?.votos_favoraveis}
        votosContrarios={votacao?.votos_contrarios}
        projetoLeiId={votacao?.projeto_lei_id}
      />

      <SimpleGrid columns={2} spacing={5}>
        <ParlamentaresFavoraveis projetoLeiId={votacao?.projeto_lei_id} votos={votosFavoraveis} />
        <ParlamentaresContra projetoLeiId={votacao?.projeto_lei_id} votos={votosContrarios} />
      </SimpleGrid>
    </Box>
  )
}
