// Chakra imports
import { Box, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
// Custom components
import Card from 'components/card/Card.js'
import React from 'react'
import { Flex } from '@chakra-ui/react'

export default function Information(props) {
  const { title, value, ...rest } = props
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textColorSecondary = 'gray.400'
  const bg = useColorModeValue('white', 'navy.700')

  let icon

  return (
    <Card bg={bg} {...rest}>
      <Box>
        <Flex flexDirection={'row'} justifyContent="space-between">
          {/* <FontAwesomeIcon
            icon={
              title === 'Votos Favoráveis' ? faThumbsUp : title === 'Votos Contrários' ? faThumbsDown : null
            }
            style={{
              padding: '5px',
              borderRadius: 50,

              color:
                title === 'Votos Favoráveis' ? '#01B574' : title === 'Votos Contrários' ? '#EE5D50' : null
            }}
          /> */}
          <Flex alignItems="center">
            <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
              {title}
            </Text>
            <Text color={textColorPrimary} fontWeight="800" fontSize="lg" ml="20px">
              {value}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Card>
  )
}
