import axios from '../../../../api/axios';
import {
  Avatar,
  Flex,
  Table,
  Link,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useMemo, useEffect, useState } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

// Custom components
import Card from 'components/card/Card';

export default function CheckTable(props) {
  const [votos, setVotos] = useState({});
  const [listaParlamentares, setListaParlamentares] = useState([]);

  function ordenarPorRank(listaParlamentares) {
    return listaParlamentares.sort((a, b) => b.rank - a.rank);
  }

  useEffect(() => {
    async function getParlamentarLista() {
      const request = await axios.get(`/parlamentar`);
      setListaParlamentares(request.data.data);
      return request;
    }
    getParlamentarLista();
  }, []);

  const listaParlamentaresOrdenados = ordenarPorRank(listaParlamentares);

  listaParlamentares.forEach((parlamentar) => {
    // console.log(parlamentar);
  });

  // useEffect(() => {
  //   async function getVotos() {
  //     const request = await axios.get(`/voto?populateDados=true`);
  //     setVotos(request.data);
  //     return request;
  //   }
  //   getVotos();
  // }, []);

  // function projetosVotadosPorParlamentar(votos) {
  //   const resultado = {};

  //   votos.forEach((votacao) => {
  //     const {
  //       parlamentar_id,
  //       projeto_lei,
  //       projeto_lei_id,
  //       voto,
  //       parlamentar: { nome, cargo, foto, instagram, email, partido_id },
  //     } = votacao;
  //     // Verifica se o parlamentar já está no mapa
  //     if (!resultado[parlamentar_id]) {
  //       resultado[parlamentar_id] = {
  //         parlamentar_id,
  //         nome,
  //         cargo,
  //         foto,
  //         instagram,
  //         email,
  //         partido_id,
  //         projetos_votados: [],
  //         pontuacao: 0,
  //       };
  //     }

  //     // Adiciona o projeto de lei ao array de projetos votados do vereador
  //     resultado[parlamentar_id].projetos_votados.push({
  //       projeto_lei_id,
  //       projeto: projeto_lei.projeto,
  //       ano: projeto_lei.ano,
  //       tipo: projeto_lei.tipo,
  //       voto,
  //       pontos: projeto_lei.pontos,
  //       impacto: projeto_lei.impacto,
  //     });

  //     if (
  //       (voto === 'favoravel' && projeto_lei?.impacto.includes('Favorável')) ||
  //       (voto === 'contra' && projeto_lei?.impacto.includes('Contrário'))
  //     ) {
  //       resultado[parlamentar_id].pontuacao =
  //         resultado[parlamentar_id].pontuacao + 1;
  //     }
  //     if (
  //       (voto === 'favoravel' && projeto_lei?.impacto.includes('Contrário')) ||
  //       (voto === 'contra' && projeto_lei?.impacto.includes('Favorável'))
  //     ) {
  //       resultado[parlamentar_id].pontuacao =
  //         resultado[parlamentar_id].pontuacao - 1;
  //     }
  //   });

  //   // Converte o objeto de resultados em um array
  //   return Object.values(resultado);
  // }

  // function ordenarPorMaiorPontuacao(data) {
  //   const array = Object.values(data);
  //   // Ordena o array baseado na pontuação
  //   array.sort((a, b) => b.pontuacao - a.pontuacao);

  //   return array;
  // }

  // console.log('votos:', votos);

  const { columnsData } = props;

  // const melhoresParlamentaresOrdenados = useMemo(() => {
  //   if (votos && votos.length > 0) {
  //     const projetosPorParlamentar = projetosVotadosPorParlamentar(votos);
  //     return ordenarPorMaiorPontuacao(projetosPorParlamentar);
  //   }
  //   return [];
  // }, [votos]); // A dependência é 'votos'

  const columns = useMemo(() => columnsData, [columnsData]);
  // const data = useMemo(
  //   () => melhoresParlamentaresOrdenados,
  //   [melhoresParlamentaresOrdenados]
  // );
  const data = useMemo(
    () => listaParlamentaresOrdenados,
    [listaParlamentaresOrdenados]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      // initialState: { pageIndex: 0, pageSize: 5 },
      initialState: { pageIndex: 0, pageSize: 35 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  return (
    <Card
      direction='column'
      w='100%'
      h='350px'
      px='0px'
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      overflowY={'scroll'}
    >
      <Flex
        px='25px'
        justify='space-between'
        align='left'
        flexDirection='column'
      >
        <Text
          color={textColor}
          fontSize='20px'
          fontWeight='700'
          lineHeight='100%'
          mb='5px'
        >
          Pontuação dos Vereadores
        </Text>
        <Text color='#a3aed0' fontSize='12px' lineHeight='100%'>
          Pontuação dos parlamentarem mais atuantes no fomento ao
          Empreendedorismo.
        </Text>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Link href={`/public/parlamentar/${row.original.id}`}>
                    <Flex alignItems='center'>
                      <Avatar
                        size='md'
                        name={row.original.nome}
                        src={row.original.foto}
                        mr='10px'
                      />
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {row.original.nome}
                      </Text>
                    </Flex>
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Flex align='center'>
                    <Text
                      me='10px'
                      color={textColor}
                      fontSize='sm'
                      fontWeight='700'
                    >
                      {row.original.rank}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
