import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../api/axios';
// Chakra imports
import { Box, Grid, SimpleGrid } from '@chakra-ui/react';

// Custom components
import Banner from 'views/public/partido/components/Banner';
import Projects from 'views/public/partido/components/Projects';
import ParlamentaresPartido from 'views/public/partido/components/ParlamentaresPartido';
import Storage from 'views/public/partido/components/Storage';
import Upload from 'views/public/partido/components/Upload';

export default function Overview() {
  const { partidoId } = useParams();
  const [partido, setPartido] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(`/partido/${partidoId}`);
      // console.log('request: ', request);
      setPartido(request.data);
      return request;
    }
    fetchData();
  }, [partidoId]);

  // console.log('partido', partido);
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {/* Main Fields */}
      <SimpleGrid columns={2} gap={5}>
        <Banner
          gridArea='1 / 2 / 2 / 2'
          logo={partido.logo}
          nome={partido.nome}
          numeroEleitoral={partido.numeroEleitoral}
          descricao={partido.descricao}
          sigla={partido.sigla}
          twitter={partido.twitter}
          youtube={partido.youtube}
          instagram={partido.instagram}
          telegram={partido.telegram}
          facebook={partido.facebook}
        />
        {/* <Projects
          gridArea='1 / 2 / 2 / 2'
          banner='{banner}'
          name='Adela Parkson'
          job='Product Designer'
        /> */}
        <ParlamentaresPartido gridArea='1 / 2 / 2 / 2' partidoId={partidoId} />
      </SimpleGrid>
    </Box>
  );
}
