/* eslint-disable */
import {
  Avatar,
  Button,
  Flex,
  Link,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'

import { getUserAuthenticationRole } from 'hooks/authService'
const userRole = getUserAuthenticationRole()

// Custom components
import Card from 'components/card/Card'
import Menu from 'components/menu/MainMenu'
import formatDateDDMMYYYY from 'hooks/formatDateDDMMYYYY'
import React, { useMemo, useState, useEffect } from 'react'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

export default function Votacao(props) {
  const { columnsData, tableData } = props

  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])

  // useEffect(() => {
  //   app
  //     .service('votacoes')
  //     .find({})
  //     .then((response) => {
  //       setVotacoes(response.data);
  //     });
  // }, []);
  // console.log('votacoes', votacoes);

  function getPartidoName(id) {
    const item = listaPartidos.find((item) => item.id === id)
    return item ? item.nome : null
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex, pageSize },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage
  } = tableInstance
  initialState.pageSize = 20

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Tabela de votações
        </Text>
        <Menu />
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            // console.log('row', row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Link href={`/public/votacao/${row.original.id}`}>
                    <Flex alignItems="center">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {row.original.projeto_lei_name}
                      </Text>
                    </Flex>
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="center">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      {formatDateDDMMYYYY(row.original.data)}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.votos_favoraveis}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.votos_contrarios}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.votos_favoraveis > row.original.votos_contrarios ? 'Aprovado' : 'Reprovado'}
                  </Text>
                </Td>
                {userRole === 'admin' && (
                  <Td
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    borderColor="transparent"
                  >
                    <Text color={textColor} fontSize="sm" fontWeight="300">
                      <Link href={`/admin/votacoes/${row.original.id}`}>
                        <span
                          style={{
                            padding: '2px 10px',
                            borderRadius: '50px',
                            background: '#eaeaea',
                            fontSize: '12px',
                            color: '#8f9bba'
                          }}
                        >
                          Editar
                        </span>
                      </Link>
                    </Text>
                  </Td>
                )}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Flex style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage} size="sm">
          Anterior
        </Button>
        <Text fontSize="sm">
          Página{' '}
          <strong>
            {pageIndex + 1} de {Math.ceil(data.length / pageSize)}
          </strong>
        </Text>
        <Button onClick={() => nextPage()} disabled={!canNextPage} size="sm">
          Próxima
        </Button>
      </Flex>
    </Card>
  )
}
