// Chakra imports
import {
  Image,
  Avatar,
  Box,
  Flex,
  Text,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import React from 'react';
import SocialNetworkUsername from './cleanUsernames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faYoutube,
  faFacebook,
  faTelegram,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

//Assets
import banner from 'assets/img/bsndeira_goiania.jpg';

export default function Banner(props) {
  const {
    logo,
    nome,
    numeroEleitoral,
    sigla,
    descricao,
    twitter,
    youtube,
    instagram,
  } = props;
  // Chakra Color Mode

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important'
  );
  return (
    <Card mb={{ base: '0px', lg: '20px' }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='6px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={`https://faroldoempreendedorismo.com.br/assets/partidos/${logo}`}
        h='190px'
        w='190px'
        mt='-100px'
        border='4px solid'
        borderColor={borderColor}
        borderRadius={150}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {nome}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {`${numeroEleitoral} - ${sigla}`}
      </Text>
      <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Link href={twitter}>
            <FontAwesomeIcon
              icon={faTwitter}
              style={{
                color: '#ffffff',
                background: '#00acee',
                padding: 10,
                borderRadius: 50,
              }}
            />
            <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
              <SocialNetworkUsername url={twitter} />
            </Text>
          </Link>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Link href={youtube}>
            <FontAwesomeIcon
              icon={faYoutube}
              style={{
                color: '#ffffff',
                background: '#cc0000',
                padding: 10,
                borderRadius: 50,
              }}
            />
            <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
              <SocialNetworkUsername url={instagram} />
            </Text>
          </Link>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <Link href={instagram}>
            <FontAwesomeIcon
              icon={faInstagram}
              style={{
                color: '#ffffff',
                background: '#e1306c',
                padding: 10,
                borderRadius: 50,
              }}
            />
            <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
              <SocialNetworkUsername url={instagram} />
            </Text>
          </Link>
        </Flex>
      </Flex>
      <Text mt='20px' align='left'>
        {descricao}
      </Text>
    </Card>
  );
}
