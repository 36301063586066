import axios from '../../../../api/axios';
import {
  Avatar,
  Flex,
  Table,
  Link,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useMemo, useEffect, useState } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

// Custom components
import Card from 'components/card/Card';

export default function CheckTable(props) {
  const [listaParlamentares, setListaPartidos] = useState([]);

  function ordenarPorRank(listaParlamentares) {
    return listaParlamentares.sort((a, b) => b.rank - a.rank);
  }

  useEffect(() => {
    async function getPartidos() {
      const request = await axios.get(`/partido`);
      setListaPartidos(request.data.data);
      return request;
    }
    getPartidos();
  }, []);

  const listaParlamentaresOrdenados = ordenarPorRank(listaParlamentares);

  // listaParlamentares.forEach((parlamentar) => {
  //   // console.log(parlamentar);
  // });

  function ordenarPorRankPartidos(listaParlamentares) {
    // Criar um objeto para armazenar o somatório do rank de cada partido
    const somatorioPorPartido = {};

    // Iterar sobre a lista de parlamentares para calcular o somatório do rank por partido
    listaParlamentares.forEach((parlamentar) => {
      const partidoId = parlamentar.partido_id;
      const rank = parlamentar.rank;

      // Se o partido ainda não estiver no objeto, inicializamos seu somatório como zero
      if (!somatorioPorPartido[partidoId]) {
        somatorioPorPartido[partidoId] = 0;
      }

      // Adicionar o rank do parlamentar ao somatório do partido
      somatorioPorPartido[partidoId] += rank;
    });

    // Converter o objeto em um array de objetos { partido_id: ..., somatorioRank: ... }
    const somatorioArray = Object.keys(somatorioPorPartido).map(
      (partidoId) => ({
        partido_id: parseInt(partidoId), // Converter para número inteiro
        somatorioRank: somatorioPorPartido[partidoId],
      })
    );

    // Ordenar o array de somatório pelo somatórioRank em ordem decrescente
    somatorioArray.sort((a, b) => b.somatorioRank - a.somatorioRank);

    return somatorioArray;
  }

  const listaPartidosOrdenados = ordenarPorRankPartidos(listaParlamentares);
  // console.log('listaPartidosOrdenados: ', listaPartidosOrdenados);

  const { columnsData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(
    () => listaParlamentaresOrdenados,
    [listaParlamentaresOrdenados]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  return (
    <Card
      direction='column'
      w='100%'
      h='350px'
      px='0px'
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      overflowY={'scroll'}
    >
      <Flex
        px='25px'
        justify='space-between'
        align='left'
        flexDirection='column'
      >
        <Text
          color={textColor}
          fontSize='20px'
          fontWeight='700'
          lineHeight='100%'
          mb='5px'
        >
          Pontuação dos Partidos
        </Text>
        <Text color='#a3aed0' fontSize='12px' lineHeight='100%'>
          Pontuação dos partidos quanto ao fomento do empreendedorismo
        </Text>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Link href={`/public/partido/${row.original.id}`}>
                    <Flex alignItems='center'>
                      <Avatar
                        size='md'
                        name={row.original.nome}
                        src={`https://faroldoempreendedorismo.com.br/assets/partidos/${row.original.logo}`}
                        mr='10px'
                      />
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {row.original.nome}
                      </Text>
                    </Flex>
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Flex align='center'>
                    <Text
                      me='10px'
                      color={textColor}
                      fontSize='sm'
                      fontWeight='700'
                    >
                      {row.original.rank}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
