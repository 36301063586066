// Chakra imports
import { Box, Flex, Link, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react'
// Custom components
import Card from 'components/card/Card.js'
import React from 'react'
// Assets
import { ProjectPoints } from './projectPoints'
import { ProjectThumbs } from './projectThumbs'
import { getUserAuthenticationRole } from 'hooks/authService'
const userRole = getUserAuthenticationRole() || ''

export default function Voto(props) {
  const { title, link, projetoUrl, voto, impacto, pontos, temas, status, ...rest } = props
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textColorSecondary = 'gray.400'
  const brandColor = useColorModeValue('brand.500', 'white')
  const bg = useColorModeValue('white', 'navy.700')

  // let corImpacto = '#cccccc';
  // if (pontos === '1') {
  //   corImpacto = '#55846C';
  // }
  // if (pontos === '-1') {
  //   corImpacto = '#845555';
  // }

  return (
    <Card bg={bg} {...rest} p="14px">
      <Link href={link} target="blank">
        <Flex align="center" direction={{ base: 'column', md: 'row' }}>
          <ProjectThumbs pontos={pontos} />
          <Box mt={{ base: '10px', md: '0' }}>
            <Text color={textColorPrimary} fontWeight="700" fontSize="md" mb="0px">
              {`${title} • ${temas} `}
              <span style={{ fontWeight: '500', marginLeft: '10px' }}>
                {userRole === 'admin' ? `(${status})` : ' '}
              </span>
              <div
                style={{
                  fontWeight: '500',
                  color: 'gray',
                  fontSize: '15px',
                  marginLeft: '0px'
                }}
              >
                {`Projeto ${impacto} ao Empreendedorismo`}
              </div>
              <ProjectPoints style={{ display: 'inline', width: '50px' }} pontos={pontos} voto={voto} />
            </Text>
          </Box>
        </Flex>
        <Flex align="center"></Flex>
      </Link>
    </Card>
  )
}
