// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Project from 'views/public/projetoLei/components/Project';
import axios from '../../../../api/axios';
import convertToPoints from '../../../../hooks/convertToPoints';

export default function Projects(props) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset'
  );

  const [dados, setData] = useState([]);
  const { projetoLeiId } = useParams();

  console.log('ID Projeto-Lei', projetoLeiId);

  // useEffect(() => {
  //   app
  //     .service('voto')
  //     .find({
  //       query: {
  //         $limit: 150,
  //         projeto_lei_id: projetoLeiId,
  //       },
  //     })
  //     .then((response) => {
  //       console.log('response.data', response.data);
  //       setData(response.data);
  //     });
  // }, []);
  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(
        `/voto?populateDados=true&projeto_lei_id=${projetoLeiId}`
      );
      setData(request.data);
      // console.log('Votos', request.data);
      return request;
    }
    fetchData();
  }, [projetoLeiId]);

  console.log('Dados de Projetos', dados);

  function contarVotos(votos) {
    let favoraveis = 0;
    let contrarios = 0;
    let abstencao = 0;

    votos.forEach((voto) => {
      if (voto.voto === 'favoravel') {
        favoraveis++;
      } else if (voto.voto === 'contra') {
        contrarios++;
      } else {
        abstencao++;
      }
    });

    return {
      favoraveis: favoraveis,
      contrarios: contrarios,
      abstencoes: abstencao,
    };
  }

  const { favoraveis, contrarios } = contarVotos(dados);

  let statusProjeto = 'Não votado';

  if (favoraveis >= contrarios) {
    statusProjeto = 'Aprovado';
  }
  if (favoraveis < contrarios) {
    statusProjeto = 'Rejeitado';
  }

  return (
    <Card mb={{ base: '0px', '2xl': '20px' }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'
      >
        Parlamentares que votaram o projeto
      </Text>
      <SimpleGrid columns={3} gap={5}>
        <Card
          style={{
            borderRadius: '20px',
            backgroundClip: 'border-box',
            boxShadow: '0px 18px 40px rgba(112, 144, 176, 0.12)',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Votos à favor<span style={{ fontWeight: '800' }}>{favoraveis}</span>
        </Card>
        <Card
          style={{
            borderRadius: '20px',
            backgroundClip: 'border-box',
            boxShadow: '0px 18px 40px rgba(112, 144, 176, 0.12)',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Votos contra<span style={{ fontWeight: '800' }}>{contrarios}</span>
        </Card>
        <Card
          style={{
            borderRadius: '20px',
            backgroundClip: 'border-box',
            boxShadow: '0px 18px 40px rgba(112, 144, 176, 0.12)',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Status<span style={{ fontWeight: '800' }}>{statusProjeto}</span>
        </Card>
      </SimpleGrid>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Acompanhe como foram as votações do parlamentar em projetos de lei que
        impactam no empreendedorismo.
      </Text>

      {dados.length > 0 &&
        dados.map((item) => (
          <Project
            boxShadow={cardShadow}
            mb='20px'
            voto={item.voto}
            title={`${item.tipo} ${item.projeto}/${item.ano}`}
            dados={item}
            image={item.parlamentar && item.parlamentar.foto}
            link={`/public/projetoLei/${item.id}`}
            key={item}
            impacto={item.projeto_lei && item.projeto_lei.impacto}
            parlamentarId={item.parlamentar && item.parlamentar.id}
            parlamentar={item.parlamentar && item.parlamentar.nome}
            pontos={item.projeto_lei && item.projeto_lei.pontos}
          />
        ))}
    </Card>
  );
}
