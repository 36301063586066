import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

export function ProjectThumbs(props) {
  const { pontos } = props;
  let icon;
  let bgThumb;
  let thumbColor;

  switch (pontos) {
    case '-1':
      icon = faThumbsDown;
      bgThumb = '#f0d7d7';
      thumbColor = '#845555';
      break;
    case '1':
      icon = faThumbsUp;
      bgThumb = '#d7f0e3';
      thumbColor = '#55846c';
      break;
    default:
      // icon = faFaceThinking;
      icon = faThumbsDown;
      bgThumb = '#f0ebd7';
      thumbColor = '#847655';
      break;
  }

  return (
    <FontAwesomeIcon
      icon={icon}
      style={{
        padding: 20,
        background: bgThumb,
        marginRight: 10,
        borderRadius: 50,
        color: thumbColor,
      }}
    />
  );
}
