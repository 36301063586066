/* eslint-disable */
import {
  Avatar,
  Button,
  Flex,
  Link,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { app } from 'api/feathers';
import axios from '../../../../api/axios';

import { getUserAuthenticationRole } from 'hooks/authService';
const userRole = getUserAuthenticationRole();

// Custom components
import Card from 'components/card/Card';
import { AndroidLogo, AppleLogo, WindowsLogo } from 'components/icons/Icons';
import Menu from 'components/menu/MainMenu';
import React, { useMemo, useState, useEffect } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

export default function Parlamentar(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const [votacoes, setVotacoes] = useState([]);

  useEffect(() => {
    async function getVotacoes() {
      const votosParlamentares = await axios.get(`/voto?populateDados=true`);
      setVotacoes(votosParlamentares.data);
      // console.log('votosParlamentares', votosParlamentares.data);
      return votosParlamentares;
    }
    getVotacoes();
  }, []);

  const [listaPartidos, setPartidos] = useState([]);
  useEffect(() => {
    app
      .service('partido')
      .find({
        query: {
          $limit: 50,
        },
      })
      .then((response) => {
        setPartidos(response.data);
      });
  }, []);
  // console.log('listaPartidos', listaPartidos);

  function getPartidoName(id) {
    const item = listaPartidos.find((item) => item.id === id);
    return item ? item.nome : null;
  }

  function votacoesDoParlamentar(votacoes, parlamentarId) {
    // Filtra os votos que correspondem ao parlamentarId
    const votosDoParlamentar = votacoes.filter(
      (voto) => voto.parlamentar_id === parlamentarId
    );

    // Soma os votos favoráveis e contrários
    let somaVotosFavoraveis = 0;
    let somaVotosContrarios = 0;

    votosDoParlamentar.forEach((voto) => {
      if (voto.voto === 'favoravel') {
        somaVotosFavoraveis++;
      } else if (voto.voto === 'contra') {
        somaVotosContrarios++;
      }
    });

    // Retorna um objeto com a soma dos votos favoráveis e contrários
    return {
      votosFavoraveis: somaVotosFavoraveis,
      votosContrarios: somaVotosContrarios,
      votosProferidos: somaVotosFavoraveis + somaVotosContrarios,
    };
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex, pageSize },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = tableInstance;
  initialState.pageSize = 20;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'
        >
          Parlamentares
        </Text>
        <Menu />
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            console.log('row', row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Link href={`/public/parlamentar/${row.original.id}`}>
                    <Flex alignItems='center'>
                      <Avatar
                        size='lg'
                        name={row.original.nome}
                        src={row.original.foto}
                        mr='10px'
                      />
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {row.original.nome}
                      </Text>
                    </Flex>
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Flex align='center'>
                    <Link href={`/public/partido/${row.original.partido_id}`}>
                      <Text
                        me='10px'
                        color={textColor}
                        fontSize='sm'
                        fontWeight='400'
                      >
                        {getPartidoName(row.original.partido_id)}
                      </Text>
                    </Link>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Text color={textColor} fontSize='sm' fontWeight='400'>
                    {
                      votacoesDoParlamentar(votacoes, row.original.id)
                        .votosProferidos
                    }
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Text color={textColor} fontSize='sm' fontWeight='400'>
                    {row.original.rank}
                  </Text>
                </Td>
                {userRole === 'admin' && (
                  <Td
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    borderColor='transparent'
                  >
                    <Text color={textColor} fontSize='sm' fontWeight='300'>
                      <Link href={`/admin/parlamentar/${row.original.id}`}>
                        <span
                          style={{
                            padding: '2px 10px',
                            borderRadius: '50px',
                            background: '#eaeaea',
                            fontSize: '12px',
                            color: '#8f9bba',
                          }}
                        >
                          Editar
                        </span>
                      </Link>
                    </Text>
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          size='sm'
        >
          Anterior
        </Button>
        <Text fontSize='sm'>
          Página{' '}
          <strong>
            {pageIndex + 1} de {Math.ceil(data.length / pageSize)}
          </strong>
        </Text>
        <Button onClick={() => nextPage()} disabled={!canNextPage} size='sm'>
          Próxima
        </Button>
      </Flex>
    </Card>
  );
}
