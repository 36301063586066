import React from 'react';

export function ProjectPoints({ pontos, voto }) {
  // switch (pontos) {
  //   case '1':
  //     cor = 'green';
  //     break;
  //   case '-1':
  //     cor = 'red';
  //     break;
  //   default:
  //     cor = '#837554';
  //     break;
  // }

  let ponto = '';
  let cor = '#837554';

  if (
    (voto === 'favoravel' && pontos === '1') ||
    (voto === 'contra' && pontos === '-1')
  ) {
    cor = 'green';
    ponto = ' 1 pts';
  }
  if (
    (voto === 'favoravel' && pontos === '-1') ||
    (voto === 'contra' && pontos === '1')
  ) {
    cor = 'red';
    ponto = ' -1 pts';
  }

  return (
    <div>
      <text style={{ color: cor, fontWeight: '700', marginRight: '4px' }}>
        {`Votou ${voto === 'favoravel' ? 'favorável' : 'contra'}  ${ponto}`}
      </text>
    </div>
  );
}
