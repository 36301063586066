import React, { useState, useEffect } from 'react';
import { app } from '../../../api/feathers';

import { Box, SimpleGrid } from '@chakra-ui/react';
import Parlamentar from './components/ParlamentarTable';

export default function Settings() {
  const [dados, setData] = useState([]);

  useEffect(() => {
    app
      .service('parlamentar')
      .find({
        query: {
          $limit: 50,
        },
      })
      .then((response) => {
        setData(response.data);
      });
  }, []);

  // console.log('dados', dados);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb='20px' columns={1} spacing={{ base: '20px', xl: '20px' }}>
        <Parlamentar
          columnsData={[
            {
              Header: 'Parlamentar',
              accessor: 'nome',
            },
            {
              Header: 'Partido',
              accessor: 'partido_id',
            },
            {
              Header: 'Votações',
              accessor: 'votacoes',
            },
            {
              Header: 'Pontos',
              accessor: 'rank',
            },
          ]}
          tableData={dados}
        />
      </SimpleGrid>
    </Box>
  );
}
