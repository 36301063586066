// Chakra imports
import { Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';
import React from 'react';
import Project from 'views/public/votacao/components/Project';

export default function Projects(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset'
  );

  const { votos } = props;

  return (
    <Card mb={{ base: '0px', '2xl': '20px' }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'
      >
        Votaram contra
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Abaixo a lista dos parlamentares que votaram contra a aprovação do
        projeto.
      </Text>
      {votos &&
        votos.map((votoContrario) => (
          <Project
            boxShadow={cardShadow}
            mb='20px'
            image={votoContrario?.parlamentar?.foto}
            voto={votoContrario.voto}
            parlamentarId={votoContrario?.parlamentar_id}
            parlamentar={votoContrario?.parlamentar?.nome}
            pontos={votoContrario?.projeto_lei?.pontos}
          />
        ))}
    </Card>
  );
}
