import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../../../api/axios'
// Chakra imports
import { Box, Grid, SimpleGrid } from '@chakra-ui/react'

// Custom components
import Banner from 'views/public/profile/components/Banner'
import General from 'views/public/profile/components/General'
import Votacoes from 'views/public/profile/components/Votacoes'
// import Notifications from 'views/public/profile/components/Notifications';
import Projects from 'views/public/profile/components/Projects'
import Relatorias from 'views/public/profile/components/Relatorias'

// Assets
import banner from 'assets/img/bsndeira_goiania.jpg'
// import avatar from 'assets/img/parlamentares/deniciotrindade.jpg';

export default function Overview() {
  const { parlamentarId } = useParams()
  const [parlamentar, setParlamentar] = useState([])
  const [partido, setPartido] = useState('')

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(`/parlamentar/${parlamentarId}`)
      setParlamentar(request.data)
      return request
    }
    parlamentarId && fetchData()
  }, [parlamentarId])

  useEffect(() => {
    async function fetchData() {
      // console.log(parlamentar);
      const request = await axios.get(`/partido/${parlamentar?.partido_id}`)
      setPartido(`${request?.data?.nome} (${request?.data?.sigla})`)

      return request
    }
    parlamentar.partido_id && fetchData()
  }, [parlamentar])

  // console.log('parlamentar: ', parlamentar);
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {/* Main Fields */}
      <SimpleGrid columns={{ base: 1, lg: 2 }} gap={5}>
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          avatar={parlamentar.foto}
          name={parlamentar.nome}
          job={parlamentar.cargo ? parlamentar.cargo : 'Vereador Municipal'}
          twitter={parlamentar.twitter}
          youtube={parlamentar.youtube}
          instagram={parlamentar.instagram}
          telegram={parlamentar.telegram}
          facebook={parlamentar.facebook}
          bio={parlamentar.bio}
          ranking={parlamentar.ranking}
          gabinete={parlamentar.gabinete}
          partido={partido}
          email={parlamentar.email}
          telefones={parlamentar.phone}
          nascimento={parlamentar.nascimento}
          endereco={parlamentar.endereco}
          key={parlamentar}
        />
        {/* <General
          gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
          minH='365px'
          pe='20px'
          bio={parlamentar.bio}
          ranking={parlamentar.ranking}
          gabinete={parlamentar.gabinete}
          partido={partido}
          email={parlamentar.email}
          telefones={parlamentar.phone}
          nascimento={parlamentar.nascimento}
        /> */}
        <Projects
          gridArea="1 / 2 / 2 / 2"
          banner={banner}
          avatar={parlamentar.foto}
          nome={parlamentar.nome}
          key={parlamentar.nome}
        />
        <Relatorias
          gridArea="1 / 2 / 2 / 2"
          banner={banner}
          avatar={parlamentar.foto}
          nome={parlamentar.nome}
          key={parlamentar.nome}
        />
        <Votacoes nome={parlamentar.nome} key={parlamentar.nome} />
        {/* <Storage
          gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
          used={25.6}
          total={50}
        /> 
        <Upload
          gridArea={{
            base: '3 / 1 / 4 / 2',
            lg: '1 / 3 / 2 / 4',
          }}
          minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
          pe='20px'
          pb={{ base: '100px', lg: '20px' }}
        />*/}
      </SimpleGrid>
    </Box>
  )
}
