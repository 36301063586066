import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../../../api/axios'
import authHeader from 'hooks/authHeader'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  useColorModeValue,
  Text,
  Stack,
  Wrap
} from '@chakra-ui/react'

import Card from '../../../components/card/Card'
import { Icon } from '@chakra-ui/react'
import { NotAllowedIcon } from '@chakra-ui/icons'

export default function Settings() {
  const { votacaoId } = useParams()
  const textColor = useColorModeValue('navy.700', 'white')

  const [listaProjetosNaoVotados, setListaProjetosNaoVotados] = useState([])
  const [listaParlamentares, setListaParlamentares] = useState([])
  const [nomeDoProjeto, setNomeDoProjeto] = useState('')
  const [disabledBoxes, setDisabledBoxes] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get('/projeto-lei')
      setListaProjetosNaoVotados(request?.data?.data)
      // console.log('request.data', request.data.data);
      return request.data
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function getParlamentares() {
      const request = await axios.get('/parlamentar')
      setListaParlamentares(request?.data?.data)
      // console.log('parlamentares', request.data.data);
      return request.data
    }
    getParlamentares()
  }, [])

  // console.log('listaProjetosNaoVotados', listaProjetosNaoVotados);
  // console.log('listaParlamentares', listaParlamentares);

  const [formData, setFormData] = useState({
    projeto_lei_id: '',
    dataVotacao: ''
  })

  // console.log('formData.dataVotacao:', formData?.dataVotacao);

  const [submitData, setSubmitData] = useState([])
  const [selectedButtons, setSelectedButtons] = useState({})
  const [isDataExists, setIsDataExists] = useState(false)
  const [sendAlert, setSendAlert] = useState('')

  const handleVotoFavoravel = (parlamentarId) => {
    setDisabledBoxes((prevDisabledBoxes) => [
      ...prevDisabledBoxes,
      parlamentarId
      // console.log('selectedButtons', selectedButtons),
    ])
    setSelectedButtons((prevSelectedButtons) => {
      const currentSelection = prevSelectedButtons[parlamentarId]
      const updatedSelection = currentSelection === 'favoravel' ? null : 'favoravel'

      if (currentSelection === 'favoravel') {
        setSubmitData((prevSubmitData) =>
          prevSubmitData.filter((data) => data.parlamentar_id !== parlamentarId)
        )
      } else {
        const newSubmitData = {
          data: formData.dataVotacao,
          projeto_lei_id: formData.projeto_lei_id,
          parlamentar_id: parlamentarId,
          voto: 'favoravel'
        }
        setSubmitData((prevSubmitData) => [...prevSubmitData, newSubmitData])
      }
      return {
        ...prevSelectedButtons,
        [parlamentarId]: updatedSelection === 'favoravel' ? updatedSelection : null
      }
    })
  }

  const handleVotoContra = (parlamentarId) => {
    setDisabledBoxes((prevDisabledBoxes) => [...prevDisabledBoxes, parlamentarId])
    setSelectedButtons((prevSelectedButtons) => {
      const currentSelection = prevSelectedButtons[parlamentarId]
      const updatedSelection = currentSelection === 'contra' ? null : 'contra'

      if (currentSelection === 'contra') {
        setSubmitData((prevSubmitData) =>
          prevSubmitData.filter((data) => data.parlamentar_id !== parlamentarId)
        )
      } else {
        const newSubmitData = {
          data: formData.dataVotacao,
          projeto_lei_id: formData.projeto_lei_id,
          parlamentar_id: parlamentarId,
          voto: 'contra'
        }
        setSubmitData((prevSubmitData) => [...prevSubmitData, newSubmitData])
      }
      return {
        ...prevSelectedButtons,
        [parlamentarId]: updatedSelection === 'contra' ? updatedSelection : null
      }
    })
  }

  function getProjetoById(projeto_lei_id, array) {
    const item = array.find((item) => item.projeto_lei_id === projeto_lei_id)
    return item ? `${item.tipo} ${item.projeto}/${item.ano}` : ''
  }
  const checkDataExists = async (projeto_lei_id) => {
    try {
      const response = await axios.get(`/voto?projeto_lei_id=${projeto_lei_id}`)
      setIsDataExists(response.data.total > 0)
    } catch (error) {
      console.error(error)
    }
  }

  const countVotosFavoraveis = (submitData) => {
    const favoraveis = submitData.reduce((count, obj) => {
      if (obj.voto === 'favoravel') {
        return count + 1
      }
      return count
    }, 0)
    return favoraveis
  }
  const countVotosContra = (submitData) => {
    const contrarios = submitData.reduce((count, obj) => {
      if (obj.voto === 'contra') {
        return count + 1
      }
      return count
    }, 0)
    return contrarios
  }

  const handleDataVotacaoChange = (e) => {
    const newDataVotacao = e.target.value
    setFormData({
      ...formData,
      dataVotacao: newDataVotacao
    })
  }

  const handleChange = (event) => {
    const { name, value } = event?.target

    // console.log(`handleChange: ${name}: ${value} `);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))

    setNomeDoProjeto(event.target?.options[event.target?.selectedIndex]?.text)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (isSubmitting) return

    setIsSubmitting(true)

    if (votacaoId) {
      try {
        for (const data of submitData) {
          await axios.patch(`/voto/${votacaoId}`, data, {
            headers: authHeader()
          })
        }
        console.log('Dados atualizados com sucesso!', submitData)
        setIsSubmitting(false)
        setSendAlert('success')
      } catch (error) {
        setIsSubmitting(false)
        setSendAlert('error')
        console.error('Ocorreu um erro ao enviar os dados:', error)
      }
    } else {
      if (submitData.length) {
        try {
          const contra = countVotosContra(submitData)
          const favor = countVotosFavoraveis(submitData)
          // console.log(`${nomeDoProjeto} - Contagem F: ${favor}, C: ${contra}`);

          await axios.post(
            '/votacoes',
            {
              data: formData.dataVotacao,
              projeto_lei: nomeDoProjeto,
              projeto_lei_id: formData.projeto_lei_id,
              votos_contrarios: contra,
              votos_favoraveis: favor
            },
            {
              headers: authHeader()
            }
          )

          for (const data of submitData) {
            await axios.post('/voto', data, {
              headers: authHeader()
            })
          }

          console.log('Votação enviada com sucesso!', submitData)
          setIsSubmitting(false)
          setSendAlert('success')
        } catch (error) {
          console.error('Ocorreu um erro ao enviar os dados:', error)
          setIsSubmitting(false)
          setSendAlert('error')
        }
      }
    }
    console.log(`Forma Data no final: ${formData}`)
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
        <Card>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Selecione o projeto de lei
              </FormLabel>
              <Select
                value={formData.projeto_lei_id}
                name="projeto_lei_id"
                onChange={handleChange}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Projetos de lei não votados"
                mb="24px"
                fontWeight="500"
                size="md"
              >
                {listaProjetosNaoVotados.map((projeto) => (
                  <option key={projeto.id} value={projeto.id}>
                    {`${projeto.tipo} ${projeto.projeto}/${projeto.ano}`}
                  </option>
                ))}
              </Select>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Data
              </FormLabel>
              <Input
                value={formData.dataVotacao}
                name="dataVotacao"
                onChange={handleDataVotacaoChange}
                type="date"
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Data da votação"
                mb="24px"
                fontWeight="500"
                size="md"
              />

              {listaParlamentares.map((parlamentar) => (
                <Box mb="5px" key={parlamentar.id}>
                  <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                    <Button
                      onClick={() => handleVotoFavoravel(parlamentar.id)}
                      colorScheme={selectedButtons[parlamentar.id] === 'contra' ? 'gray' : 'green'}
                      w="80px"
                      sx={{ borderRadius: '16px 0 0 16px' }}
                      isDisabled={selectedButtons[parlamentar.id] === 'contra'}
                    >
                      {selectedButtons[parlamentar.id] === 'favoravel' ? 'X' : 'À favor'}
                    </Button>

                    <Text fontSize="sm" ms={{ base: '0px', md: '0px' }} px="5px" fontWeight="500" size="sm">
                      {parlamentar.nome}
                    </Text>

                    <Button
                      onClick={() => handleVotoContra(parlamentar.id)}
                      colorScheme={selectedButtons[parlamentar.id] === 'favoravel' ? 'gray' : 'red'}
                      px="5px"
                      w="80px"
                      sx={{ borderRadius: '0 16px 16px 0' }}
                      isDisabled={selectedButtons[parlamentar.id] === 'favoravel'}
                    >
                      {selectedButtons[parlamentar.id] === 'contra' ? 'X' : 'Contra'}
                    </Button>
                  </Stack>
                </Box>
              ))}
              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                isLoading={isSubmitting}
                loadingText="Enviando votação"
              >
                Adicionar votação
              </Button>
              {sendAlert === 'success' && (
                <Alert status="success">
                  <AlertIcon />
                  <AlertTitle>Sucesso!</AlertTitle>
                  <AlertDescription>Dados enviados com sucesso!</AlertDescription>
                </Alert>
              )}
              {sendAlert === 'error' && isDataExists && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Erro!</AlertTitle>
                  <AlertDescription>Verifique os dados digitados e tente novamente.</AlertDescription>
                </Alert>
              )}
            </FormControl>
          </form>
        </Card>
      </SimpleGrid>
    </Box>
  )
}
