export const columnsDataCheck = [
  {
    Header: 'NOME',
    accessor: 'nome',
  },
  {
    Header: 'PTS',
    accessor: 'pontuacao',
  },
];

export const columsRankingPartidos = [
  {
    Header: 'PARTIDO',
    accessor: 'nome',
  },
  {
    Header: 'PTS',
    accessor: 'pontuacao',
  },
];

export const columnsDataComplex = [
  {
    Header: 'PROJETO',
    accessor: 'projeto_lei',
  },
  {
    Header: 'STATUS',
    accessor: 'projetoLei.status',
  },
  {
    Header: 'DATA',
    accessor: 'data',
  },
  {
    Header: 'VOTAÇÃO',
    accessor: 'performance',
  },
];
