// Chakra imports
import {
  SimpleGrid,
  Text,
  Link,
  Image,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import React from 'react';
import Information from 'views/public/projetoLei/components/Information';
import formatDateDDMMYYYY from 'hooks/formatDateDDMMYYYY';
import ProjectThumbs from './projectThumbs';
import pdf from '../../../../assets/img/pdf.png';

// Assets
export default function GeneralInformation(props) {
  const {
    nome,
    ementa,
    autor,
    partido,
    teor,
    tema,
    analise,
    impacto,
    integra,
    image,
    status,
    dataapresentacao,
    datastatus,
    fase,
    proximaetapa,
    pontos,
    casalegislativa,
    ...rest
  } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset'
  );

  return (
    <Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
      <HStack pt='10px'>
        <ProjectThumbs pontos={pontos} key={nome} />
        <SimpleGrid rows={3}>
          <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl'>
            {nome}
          </Text>
          <Text color={textColorSecondary} fontSize='md' me='26px'>
            {`${tema} - Teor do Projeto: ${teor}`}
          </Text>
          {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
            {impacto}
            {console.log(`Pontos: ${pontos}`)}
          </Text> */}
        </SimpleGrid>
      </HStack>

      <Text
        color={textColorSecondary}
        fontSize='md'
        me='26px'
        mt='40px'
        mb='40px'
      >
        <Text color={textColorPrimary} fontWeight='bold' fontSize='xl'>
          Ementa do projeto
        </Text>
        {ementa}
      </Text>
      <Link href={integra} target='_blank'>
        <Text
          color={textColorSecondary}
          fontSize='md'
          me='26px'
          mb='40px'
          alignItems='center'
          display='flex'
          style={{
            background: '#f5f5f5',
            padding: '5px 10px',
            borderRadius: '50px',
          }}
        >
          <Image src={pdf} w='50px' style={{ display: 'inline' }} />
          Veja o projeto na íntegra
        </Text>
      </Link>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        <Text color={textColorPrimary} fontWeight='bold' fontSize='xl'>
          Análise
        </Text>
        {analise}
      </Text>
      <SimpleGrid columns='2' gap='20px'>
        <Information
          boxShadow={cardShadow}
          title='Autor'
          value={`${autor}, ${partido}`}
        />
        <Information
          boxShadow={cardShadow}
          title='Casa Legislativa'
          value={casalegislativa}
        />
        <Information
          boxShadow={cardShadow}
          title='Data da Apresentação'
          value={formatDateDDMMYYYY(dataapresentacao)}
        />
        <Information
          boxShadow={cardShadow}
          title='Status'
          value={`${formatDateDDMMYYYY(datastatus)} • ${status}`}
        />
        <Information
          boxShadow={cardShadow}
          title='Fase do Projeto'
          value={fase}
        />
        <Information
          boxShadow={cardShadow}
          title='Próxima Etapa'
          value={proximaetapa}
        />
      </SimpleGrid>
    </Card>
  );
}
