// Chakra imports
import { Avatar, Box, Flex, Text, Icon, Select, SimpleGrid, useColorModeValue } from '@chakra-ui/react'
import axios from 'api/axios'
import { app } from 'api/feathers'
import { countValues, getUniqueValues } from 'hooks/countValues'
// Assets
import Usa from 'assets/img/dashboards/usa.png'
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar'
import MiniStatistics from 'components/card/MiniStatistics'
import IconBox from 'components/icons/IconBox'
import React, { useState, useEffect } from 'react'
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, MdPeople, MdVerifiedUser } from 'react-icons/md'
import PioresAutores from 'views/public/default/components/PioresAutores'
import MelhoresAutores from 'views/public/default/components/MelhoresAutores'
import MelhoresProjetos from 'views/public/default/components/MelhoresProjetos'
import MelhoresPartidos from 'views/public/default/components/MelhoresPartidos'
import PioresProjetos from 'views/public/default/components/PioresProjetos'
import PioresPartidos from 'views/public/default/components/PioresPartidos'
import DailyTraffic from 'views/public/default/components/DailyTraffic'
import PieCard from 'views/public/default/components/PieCard'
import Tasks from 'views/public/default/components/Tasks'
import TotalSpent from 'views/public/default/components/TotalSpent'
import ProjetosTemas from 'views/public/default/components/ProjetosTemas'
import { columnsDataCheck, columnsDataComplex } from 'views/public/default/variables/columnsData'
import tableDataCheck from 'views/public/default/variables/tableDataCheck.json'
import columsRankingPartidos from 'views/public/default/variables/tableDataCheck.json'
import tableDataComplex from 'views/public/default/variables/tableDataComplex.json'
import { getUserAuthenticationRole } from 'hooks/authService'
import Card from 'components/card/Card'

export default function UserReports() {
  const [dados, setDados] = useState([])
  const [votacoes, setVotacoes] = useState([])

  const [numeroParlamentares, setNumeroParlamentares] = useState('')
  const [numeroProjetosAprovados, setNumeroProjetosAprovados] = useState(0)
  const [numeroProjetosReprovados, setNumeroProjetosReprovados] = useState(0)

  const userRole = getUserAuthenticationRole() || ''
  const query = {
    $limit: 5000,
    casa_legislativa: 'Câmara Municipal de Goiânia'
  }
  if (userRole !== 'admin') {
    query.status = { $ne: 'Despublicado' }
  }

  useEffect(() => {
    app
      .service('projeto-lei')
      .find({
        query
      })
      .then((response) => {
        setDados(response?.data)
      })
  }, [])

  useEffect(() => {
    app
      .service('parlamentar')
      .find({
        query: {
          $limit: 1000
        }
      })
      .then((response) => {
        setNumeroParlamentares(response?.data?.length)
      })
  }, [])

  useEffect(() => {
    async function getVotacoes() {
      const request = await axios.get(`/votacoes?populateDados=true`)
      // const request = await axios.get(`/votacoes`)
      setVotacoes(request.data)
      console.log('request.data Votacoes1: ', request.data)
      return request.data
    }

    getVotacoes()
  }, [])

  useEffect(() => {
    console.log('Votações: ', votacoes)
    // console.log('Dados: ', dados)
  }, [votacoes, dados])

  function contarVotosEmObjeto(ListaVotacoes) {
    let resultado = {
      aprovados: 0,
      rejeitados: 0
    }

    Object.values(ListaVotacoes).forEach((votacao) => {
      if (votacao.votos_favoraveis >= votacao.votos_contrarios) {
        resultado.aprovados++
      } else if (votacao.votos_contrarios > votacao.votos_favoraveis) {
        resultado.rejeitados++
      }
    })

    return resultado
  }

  const resultado = contarVotosEmObjeto(votacoes)
  // console.log(`Projetos Aprovados: ${resultado.aprovados}, Projetos Rejeitados: ${resultado.rejeitados}`)

  // function ordenarProjetosVotosFavoraveis(data) {
  //   let somaDosVotos = {}

  //   console.log('data em contarVotosEmObjeto: ', data)

  //   // Calcular a soma dos votos favoráveis de cada projeto
  //   Array.isArray(data) &&
  //     data.forEach((votacao) => {
  //       console.log('votacao.projeto_lei_name: ', votacao.projeto_lei_name)
  //       const votacaoId = votacao.id
  //       const projetoLei = votacao.projeto_lei_name
  //       const projetoLeiId = votacao?.projeto_lei_id
  //       const votosFavoraveis = parseInt(votacao.votos_favoraveis, 10)
  //       const votosContrarios = parseInt(votacao.votos_contrarios, 10)
  //       const performance =
  //         (votacao.votos_favoraveis / (votacao.votos_contrarios + votacao.votos_favoraveis)) * 100
  //       const projetoLeiInfo = {
  //         projeto: votacao.projeto,
  //         ano: votacao.ano,
  //         tipo: votacao.tipo,
  //         pontos: votacao.pontos,
  //         status: votacao.status,
  //         impacto: votacao.impacto,
  //         temas: votacao.temas
  //       }

  //       // Verificar se o impacto do projeto é neutro
  //       if (projetoLeiInfo.impacto === 'Favorável') {
  //         // console.log('projetoLeiInfo é Favoravel', projetoLeiInfo)
  //         // const dataVotacao = new Date(votacao.data)
  //         // const dataFormatada = dataVotacao.toLocaleDateString('pt-BR', {
  //         //   day: '2-digit',
  //         //   month: '2-digit',
  //         //   year: 'numeric'
  //         // })

  //         somaDosVotos[projetoLei] = {
  //           // data: dataFormatada,
  //           projeto_lei_name: `${votacao.tipo} ${votacao.projeto}/${votacao.ano}`,
  //           projeto_lei_id: votacao.id,
  //           votos_favoraveis: parseInt(votacao.votos_favoraveis, 10),
  //           votos_contrarios: votosContrarios,
  //           somatorioVotos: votosFavoraveis + votosContrarios,
  //           performance,
  //           createdAt: votacao.createdAt,
  //           updatedAt: votacao.updatedAt,
  //           projetoLei: projetoLeiInfo
  //         }
  //       }
  //     })

  //   // Ordenar os projetos com base no número de votos favoráveis
  //   const projetosOrdenados = Object.entries(somaDosVotos).sort(
  //     (a, b) => b[1].somatorioVotos - a[1].somatorioVotos
  //   )

  //   console.log('somaDosVotossomaDosVotos', somaDosVotos)

  //   console.log('projetosOrdenados: ', projetosOrdenados)

  //   // Criar lista dos projetos com votos favoráveis ordenados
  //   const projetosFavoraveisOrdenados = projetosOrdenados
  //     .filter((projeto) => projeto[1].somatorioVotos > 0)
  //     .map((projeto) => projeto[1])

  //   console.log('projetosFavoraveisOrdenados:: ', projetosFavoraveisOrdenados)
  //   return projetosFavoraveisOrdenados
  // }

  // const listaProjetosFavoraveis = ordenarProjetosVotosFavoraveis(dados)
  // console.log('Projetos com votos favoráveis e maior número de votações:', listaProjetosFavoraveis)

  // function ordenarProjetosVotosContrarios(votacoes) {
  //   // Objeto para armazenar a soma dos votos favoráveis de cada projeto
  //   let somaDosVotos = {}

  //   // Calcular a soma dos votos favoráveis de cada projeto
  //   Array.isArray(votacoes) &&
  //     votacoes.forEach((votacao) => {
  //       const projetoLei = votacao.projeto_lei_name
  //       const projetoLeiId = votacao.projeto_lei_id
  //       const votosFavoraveis = votacao.votos_favoraveis
  //       const votosContrarios = votacao.votos_contrarios
  //       const performance =
  //         (votacao.votos_favoraveis / (votacao.votos_contrarios + votacao.votos_favoraveis)) * 100
  //       const projetoLeiInfo = {
  //         projeto: votacao['projetoLei.projeto'],
  //         ano: votacao['projetoLei.ano'],
  //         tipo: votacao['projetoLei.tipo'],
  //         pontos: votacao['projetoLei.pontos'],
  //         status: votacao['projetoLei.status'],
  //         impacto: votacao['projetoLei.impacto'],
  //         temas: votacao['projetoLei.temas']
  //       }

  //       // Verificar se o impacto do projeto é neutro
  //       if (projetoLeiInfo.impacto === 'Contrário') {
  //         const data = new Date(votacao.data)
  //         const dataFormatada = data.toLocaleDateString('pt-BR', {
  //           day: '2-digit',
  //           month: '2-digit',
  //           year: 'numeric'
  //         })

  //         somaDosVotos[projetoLei] = {
  //           id: votacao.id,
  //           data: dataFormatada,
  //           projeto_lei_name: projetoLei,
  //           projeto_lei_id: projetoLeiId,
  //           votos_favoraveis: votosFavoraveis,
  //           votos_contrarios: votosContrarios,
  //           somatorioVotos: votosFavoraveis + votosContrarios,
  //           performance,
  //           createdAt: votacao.createdAt,
  //           updatedAt: votacao.updatedAt,
  //           projetoLei: projetoLeiInfo
  //         }
  //       }
  //     })

  //   // Ordenar os projetos com base no número de votos favoráveis
  //   const projetosOrdenados = Object.entries(somaDosVotos).sort(
  //     (a, b) => b[1].somatorioVotos - a[1].somatorioVotos
  //   )

  //   // Criar lista dos projetos com votos contrarios ordenados
  //   const projetosContrariosOrdenados = projetosOrdenados
  //     .filter((projeto) => projeto[1].somatorioVotos > 0)
  //     .map((projeto) => projeto[1])

  //   return projetosContrariosOrdenados
  // }

  // const listaProjetosContrarios = ordenarProjetosVotosContrarios(votacoes)
  // console.log(
  //   'Projetos com votos contrarios e maior número de votações:',
  //   listaProjetosContrarios
  // );

  // const temasDosProjetos = getUniqueValues(dados, 'temas');
  // const votosFavoraveis = countValues(dados, 'impacto', 'Favorável');
  // const votosContra = countValues(dados, 'impacto', 'Contra');
  // const votosFavoraveisRessalvas = countValues(
  //   dados,
  //   'impacto',
  //   'Favorável com Ressalvas'
  // );
  // const votosContraRessalvas = countValues(
  //   dados,
  //   'impacto',
  //   'Favorável com Ressalvas'
  // );
  // console.log('votosFavoráveis:', votosFavoraveis);
  // console.log('temasDosProjetos:', temasDosProjetos);

  let listaProjetosFavoraveis = []
  let listaProjetosContrarios = []

  const listaVotacoes = votacoes.sort((a, b) => {
    const dateComparison = new Date(b.data) - new Date(a.data)
    if (dateComparison !== 0) {
      return dateComparison
    }
    return b.votos_favoraveis - a.votos_favoraveis
  })

  const listaVotacoesFavoraveis = listaVotacoes.filter(
    (projeto) => projeto.projeto_lei.impacto === 'Favorável'
  )

  const listaVotacoesContrarios = listaVotacoes.filter(
    (projeto) => projeto.projeto_lei.impacto === 'Contrário'
  )

  console.log('listaVotacoes: ', listaVotacoes)
  console.log('listaVotacoesFavoraveis: ', listaVotacoesFavoraveis)
  console.log('listaVotacoesContrarios: ', listaVotacoesContrarios)

  function ordenaProjetosFavoraveis(listaVotacoes) {
    console.log('listaVotacoes> ', listaVotacoes)
    listaVotacoes.map((votacao) => {
      if (votacao.projeto_lei === 'Favorável') {
        return console.log('Projeto Favorável: ', votacao.projeto_lei_name)
      }
    })
  }

  const verificaFunction = ordenaProjetosFavoraveis(votacoes)
  console.log('verificaFunction: ', verificaFunction)

  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white')
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100')

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 5, '2xl': 5 }} gap="20px" mb="20px">
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdPeople} color="#0D4384" />}
            />
          }
          name="Parlamentares"
          value={numeroParlamentares}
        />
        {/* <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color='#0D4384' />
              }
            />
          }
          name='Despesas mês anterior'
          value='$14.642.329,45'
        /> */}
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdFileCopy} color="#0D4384" />}
            />
          }
          name="Total de Projetos"
          value={dados.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(300deg, #0D4384 0%, #376eaf 100%)"
              icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
            />
          }
          name="Projetos a votar"
          value={dados.length - votacoes.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(300deg, #0D4384 0%, #376eaf 100%)"
              icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
            />
          }
          name="Projetos Aprovados"
          value={resultado.aprovados}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(300deg, #0D4384 0%, #376eaf 100%)"
              icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
            />
          }
          name="Projetos Rejeitados"
          value={resultado.rejeitados}
        />
      </SimpleGrid>
      {dados && (
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
          <Card>
            <Flex p="15px" justify="space-between" align="left" flexDirection="column">
              <Text color={textColor} fontSize="20px" fontWeight="700" lineHeight="100%" mb="5px">
                Acompanhe de perto a atuação dos parlamentares na Câmara Municipal de Goiânia em face ao tema
                Empreendedorismo.
              </Text>
              <Text color="#a3aed0" fontSize="14px" lineHeight="100%"></Text>
            </Flex>
            <Flex p="15px" justify="space-between" align="left" flexDirection="column">
              <Text color={textColor} fontSize="20px" fontWeight="700" lineHeight="100%" mb="5px">
                Como funciona a pontuação?
              </Text>
              <Text color="#a3aed0" fontSize="14px" lineHeight="100%" mb="10px">
                Entre 2023 e 2024 avaliamos os principais projetos de lei criados desde 2020 conforme seu
                impacto no empreendedorismo e atribuímos uma pontuação que passa a ser acumulada para cada
                parlamentar.
              </Text>
              <Text color={textColor}>Se o projeto propicia o desenvolvimento de novos negócios:</Text>
              <Text fontSize="14px" ml="10px" color="#a3aed0">
                + 3pts para o parlamentar que criou o projeto.
              </Text>
              <Text fontSize="14px" ml="10px" color="#a3aed0">
                + 2pts para o parlamentar que atuou como relator do projeto.
              </Text>
              <Text fontSize="14px" ml="10px" mb="10px" color="#a3aed0">
                + 1pts para o parlamentar que votou favorável ao projeto.
              </Text>

              <Text color={textColor}>Se o projeto prejudica o desenvolvimento dos negócios:</Text>
              <Text fontSize="14px" ml="10px" color="#a3aed0">
                - 3pts para o parlamentar que criou o projeto.
              </Text>
              <Text fontSize="14px" ml="10px" color="#a3aed0">
                - 2pts para o parlamentar que atuou como relator do projeto.
              </Text>
              <Text fontSize="14px" ml="10px" color="#a3aed0">
                - 1pts para o parlamentar que votou favorável ao projeto.
              </Text>
            </Flex>
          </Card>
          <ProjetosTemas key={dados} dados={dados} />
        </SimpleGrid>
      )}
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <MelhoresAutores columnsData={columnsDataCheck} tableData={tableDataCheck} />
        {/* <PioresAutores
          columnsData={columnsDataCheck}
          tableData={tableDataCheck}
        /> */}
        <MelhoresPartidos columnsData={columnsDataCheck} tableData={tableDataCheck} />
        {/* <PioresPartidos
          columnsData={columnsDataCheck}
          tableData={tableDataCheck}
        /> */}
      </SimpleGrid>

      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <DailyTraffic />
        {dados && <PieCard dados={dados} key={dados} />}
      </SimpleGrid> */}
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <MelhoresProjetos
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
          dados={listaVotacoesFavoraveis}
        />
        <PioresProjetos
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
          dados={listaVotacoesContrarios}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          {/* <Tasks /> */}
          {/* <MiniCalendar h='100%' minW='100%' selectRange={false} /> */}
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  )
}
