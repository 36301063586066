import React from 'react';

export function ParlamentarPoints({ pontos }) {
  return (
    <div>
      <text
        style={{ color: '#1b2559', fontWeight: 'bold' }}
      >{`${pontos} pts`}</text>
    </div>
  );
}
