import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../api/axios';
import authHeader from '../../../hooks/authHeader';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  SimpleGrid,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../components/card/Card';
import { Icon } from '@chakra-ui/react';
import { NotAllowedIcon } from '@chakra-ui/icons';

export default function Settings() {
  const { partidoId } = useParams();
  const textColor = useColorModeValue('navy.700', 'white');

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(`/partido/${partidoId}`);
      setFormData(request.data);
      // console.log('request.data', request.data);
      return request;
    }

    if (partidoId) {
      fetchData();
    }
  }, [partidoId]);

  const [formData, setFormData] = useState({
    nome: '',
    sigla: '',
    numeroEleitoral: '',
    logo: '',
    descricao: '',
    instagram: '',
    twitter: '',
    telegram: '',
    youtube: '',
    rank: 0,
  });
  const [isDataExists, setIsDataExists] = useState(false);
  const [sendAlert, setSendAlert] = useState('');

  const checkDataExists = async (value) => {
    try {
      const response = await axios.get(`/partido?numeroEleitoral=${value}`);
      setIsDataExists(response.data.total > 0);
    } catch (error) {
      console.error(error.response.data);
    }
  };

  const handleChangeNumeroEleitoral = (event) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      numeroEleitoral: parseInt(value),
    }));
    !partidoId && checkDataExists(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (partidoId) {
      try {
        const response = await axios.patch(`/partido/${partidoId}`, formData, {
          headers: authHeader(),
        });
        console.log('Dados atualizados com sucesso!', response.data);
        setSendAlert('success');
      } catch (error) {
        setSendAlert('error');
        console.error(
          'Ocorreu um erro ao enviar os dados:',
          error.response.data
        );
      }
    } else {
      if (!isDataExists) {
        try {
          const response = await axios.post('/partido', formData, {
            headers: authHeader(),
          });
          console.log('Dados enviados com sucesso!', response.data);
          setSendAlert('success');
        } catch (error) {
          console.error(
            'Ocorreu um erro ao enviar os dados:',
            error.response.data
          );
          setSendAlert('error');
        }
      }
    }
    // console.log(formData);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        <Card>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Nome do Partido
              </FormLabel>
              <Input
                value={formData.nome}
                name='nome'
                onChange={handleChange}
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='Nome completo do Partido'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Sigla do Partido
              </FormLabel>
              <Input
                value={formData.sigla}
                name='sigla'
                onChange={handleChange}
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='Sigla do Partido'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Código Eleitoral{' '}
                {isDataExists && (
                  <span style={{ padding: '0px 20px', color: '#cc6666' }}>
                    Este código eleitoral percente a um partido cadastrado
                  </span>
                )}
              </FormLabel>
              <Input
                value={formData.numeroEleitoral}
                name='numeroEleitoral'
                onChange={handleChangeNumeroEleitoral}
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='Número eleitoral'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Apresentação do Partido
              </FormLabel>
              <Textarea
                name='descricao'
                onChange={handleChange}
                value={formData.descricao}
                isRequired={true}
                variant='outline'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='Descrição'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Instagram
              </FormLabel>
              <Input
                value={formData.instagram}
                name='instagram'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='https://instagram.com/nome-da-conta'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Telegram
              </FormLabel>
              <Input
                value={formData.telegram}
                name='telegram'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='https://telegram.com/nome-da-conta'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Twitter
              </FormLabel>
              <Input
                value={formData.twitter}
                name='twitter'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='https://twitter.com/nome-da-conta'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Youtube
              </FormLabel>
              <Input
                value={formData.youtube}
                name='youtube'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='https://youtube.com/nome-da-conta'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Logo
              </FormLabel>
              <Input
                value={formData.logo}
                name='logo'
                onChange={handleChange}
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <Button
                type='submit'
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
              >
                {partidoId ? 'Atualizar Partido' : 'Adicionar Partido'}
              </Button>
              {sendAlert === 'success' && (
                <Alert status='success'>
                  <AlertIcon />
                  <AlertTitle>Sucesso!</AlertTitle>
                  <AlertDescription>
                    Dados enviados com sucesso!
                  </AlertDescription>
                </Alert>
              )}
              {sendAlert === 'error' ||
                (isDataExists && (
                  <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>Erro!</AlertTitle>
                    <AlertDescription>
                      Verifique os dados digitados e tente novamente.
                    </AlertDescription>
                  </Alert>
                ))}
            </FormControl>
          </form>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
