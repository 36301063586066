import React from 'react';

// Chakra imports
import { Flex, Image, Link } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';
import farolLogo from 'assets/img/farol_empreendedorismo.png';

export function SidebarBrand() {
  return (
    <Flex align='center' direction='column'>
      <Link href='/'>
        <Image w='260px' mt='0' mb='40px' src={farolLogo} />
      </Link>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
