import React, { useState, useEffect, createRef } from 'react';
import Dropzone from 'react-dropzone';
import { useParams } from 'react-router-dom';
import axios from '../../../api/axios';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  SimpleGrid,
  Select,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../components/card/Card';
import { Icon, Image } from '@chakra-ui/react';
import { NotAllowedIcon } from '@chakra-ui/icons';
import formatDate from 'hooks/formatDate';
import authHeader from '../../../hooks/authHeader';

export default function Settings() {
  const { parlamentarId } = useParams();
  const textColor = useColorModeValue('navy.700', 'white');

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
  };

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(`/parlamentar/${parlamentarId}`);
      setFormData(request.data);
      // console.log('request.data', request.data);
      return request;
    }

    if (parlamentarId) {
      fetchData();
    }
  }, [parlamentarId]);

  const [partidoList, setPartidoList] = useState([]);

  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    foto: '',
    phone: '',
    instagram: '',
    facebook: '',
    twitter: '',
    telegram: '',
    youtube: '',
    partido_id: '',
    rank: 0,
    gabinete: '',
    nascimento: '',
    cargo: '',
    bio: '',
    endereco: '',
  });

  const [isDataExists, setIsDataExists] = useState(false);
  const [sendAlert, setSendAlert] = useState('');

  const dropzoneRef = createRef();
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  useEffect(() => {
    async function fetchPartidos() {
      const request = await axios.get('/partido');
      const { data: requestData } = request;

      const partidoListData = requestData?.data.map((partido) => ({
        id: partido.id,
        nome: partido.nome,
        sigla: partido.sigla,
      }));
      const partidoListSorted = partidoListData.sort((a, b) =>
        a.nome.localeCompare(b.nome)
      );
      // console.log('partidoListSorted', partidoListSorted);
      setPartidoList(partidoListSorted);
      return request;
    }

    fetchPartidos();
  }, []);

  const checkDataExists = async (value) => {
    try {
      const response = await axios.get(`/parlamentar?nome=${value}`);
      setIsDataExists(response.data.total > 0);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeNome = (event) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      nome: value,
    }));
    !parlamentarId && checkDataExists(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // console.log(`Name: ${name}, value: ${value}`);
  };

  const handleImageChange = (event) => {
    const { image } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      foto: image,
    }));
    // console.log(
    //   'Nome do arquivo: ',
    //   event && event.target && event.target.image
    // );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (parlamentarId) {
      try {
        const response = await axios.patch(
          `/parlamentar/${parlamentarId}`,
          formData,
          { headers: authHeader() }
        );
        console.log('Dados atualizados com sucesso!', response.data);
        setSendAlert('success');
      } catch (error) {
        setSendAlert('error');
        console.error(
          'Ocorreu um erro ao enviar os dados:',
          error.response.data
        );
      }
    } else {
      if (!isDataExists) {
        try {
          const response = await axios.post('/parlamentar', formData, {
            headers: authHeader(),
          });
          console.log('Dados enviados com sucesso!', response.data);
          setSendAlert('success');
        } catch (error) {
          console.error(
            'Ocorreu um erro ao enviar os dados:',
            error.response.data
          );
          setSendAlert('error');
        }
      }
    }
    // console.log(formData);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        <Card>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Nome do Parlamentar{' '}
                {isDataExists && (
                  <span style={{ padding: '0px 20px', color: '#cc6666' }}>
                    Este nome percente a um parlamentar cadastrado
                  </span>
                )}
              </FormLabel>
              <Input
                value={formData.nome}
                name='nome'
                onChange={handleChangeNome}
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='Nome completo'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Cargo
              </FormLabel>
              {/* <Input
                value={formData.cargo}
                name='cargo'
                onChange={handleChange}
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='Vereador, deputado estadual, ...'
                mb='24px'
                fontWeight='500'
                size='md'
              /> */}
              <Select
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='autor'
                placeholder='Cargo do Parlamentar'
                mb='24px'
                fontWeight='500'
                size='md'
                name='cargo'
                value={formData.cargo}
                onChange={handleChange}
              >
                <option value='Deputado Estadual'>Deputado Estadual</option>
                <option value='Vereador'>Vereador</option>
              </Select>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Data de nascimento
              </FormLabel>
              <Input
                value={formData.nascimento}
                name='nascimento'
                type='date'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='Selecione o partido'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Partido
              </FormLabel>
              <Select
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='autor'
                placeholder='Selecione o Partido'
                mb='24px'
                fontWeight='500'
                size='md'
                value={formData.partido_id}
                onChange={handleChange}
                name='partido_id'
              >
                {partidoList.map((partido) => (
                  <option key={partido.id} value={partido.id}>
                    {partido.sigla}
                    {' - '}
                    {partido.nome}
                  </option>
                ))}
              </Select>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Bio do parlamentar
              </FormLabel>
              <Textarea
                name='bio'
                onChange={handleChange}
                value={formData.bio}
                isRequired={true}
                variant='outline'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='Apresentação do parlamentar'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Email oficial
              </FormLabel>
              <Input
                value={formData.email}
                name='email'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='informe o email público do parlamentar'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Telefone oficial
              </FormLabel>
              <Input
                value={formData.phone}
                name='phone'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='informe o telefone público do parlamentar'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Endereço do gabinete
              </FormLabel>
              <Textarea
                name='endereco'
                onChange={handleChange}
                value={formData.endereco}
                isRequired={true}
                variant='outline'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='Informe o endereço do gabinete'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Número do Gabinete
              </FormLabel>
              <Input
                value={formData.gabinete}
                name='gabinete'
                onChange={handleChange}
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='O número do gabinete do parlamentar'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Instagram
              </FormLabel>
              <Input
                value={formData.instagram}
                name='instagram'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='https://instagram.com/nome-da-conta'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Telegram
              </FormLabel>
              <Input
                value={formData.telegram}
                name='telegram'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='https://telegram.com/nome-da-conta'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Twitter
              </FormLabel>
              <Input
                value={formData.twitter}
                name='twitter'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='https://twitter.com/nome-da-conta'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Youtube
              </FormLabel>
              <Input
                value={formData.youtube}
                name='youtube'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='https://youtube.com/nome-da-conta'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Facebook
              </FormLabel>
              <Input
                value={formData.facebook}
                name='facebook'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                placeholder='https://facebook.com/nome-da-conta'
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                URL da Foto
              </FormLabel>
              <Input
                value={formData.foto}
                name='foto'
                onChange={handleChange}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                mb='24px'
                fontWeight='500'
                size='md'
              />
              <Dropzone ref={dropzoneRef} noClick noKeyboard>
                {({ getRootProps, getInputProps, acceptedFiles }) => {
                  return (
                    <div
                      className='container'
                      style={{
                        border: '1px solid #E0E5F2',
                        background: '#f9fbff',
                        borderRadius: '18px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'nowrap',
                        marginBottom: '20px',
                      }}
                    >
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input
                          {...getInputProps()}
                          value={formData.foto}
                          name='foto'
                          onDrop={handleImageChange}
                          type='image'
                          aria-label='image'
                        />
                        <button type='button' onClick={openDialog}>
                          Selecione a imagem ou arraste para cá.
                        </button>
                      </div>
                      <aside style={thumbsContainer}>
                        {acceptedFiles.map((file) => (
                          <p key={file.path}>
                            {file.path} - {file.size} bytes
                          </p>
                        ))}
                      </aside>
                    </div>
                  );
                }}
              </Dropzone>
              <Button
                type='submit'
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
              >
                {parlamentarId
                  ? `Atualizar o perfil de ${formData.nome}`
                  : 'Adicionar Parlamentar'}
              </Button>
              {sendAlert === 'success' && (
                <Alert status='success'>
                  <AlertIcon />
                  <AlertTitle>Sucesso!</AlertTitle>
                  <AlertDescription>
                    Dados enviados com sucesso!
                  </AlertDescription>
                </Alert>
              )}
              {sendAlert === 'error' ||
                (isDataExists && (
                  <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>Erro!</AlertTitle>
                    <AlertDescription>
                      Verifique os dados digitados e tente novamente.
                    </AlertDescription>
                  </Alert>
                ))}
            </FormControl>
          </form>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
