import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../api/axios';
// Chakra imports
import { Box, Grid, SimpleGrid } from '@chakra-ui/react';

// Custom components
import General from 'views/public/projetoLei/components/General';
// import Notifications from 'views/public/profile/components/Notifications';
import Projects from 'views/public/projetoLei/components/Projects';

// Assets
import banner from 'assets/img/bsndeira_goiania.jpg';

export default function Overview() {
  const { projetoLeiId } = useParams();
  const [projetoLei, setProjetoLei] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(`/projeto-lei/${projetoLeiId}`);
      setProjetoLei(request.data);
      console.log('request.data', request.data);
      return request;
    }
    fetchData();
  }, [projetoLeiId]);

  return (
    <Box mt='80px'>
      <SimpleGrid columns={3} gap={5}>
        <General
          gridArea={{ base: '1 / 1 / 3 / 2', lg: '1 / 1 / 2 / 3' }}
          minH='365px'
          pe='20px'
          nome={`${projetoLei.tipo} ${projetoLei.projeto}/${projetoLei.ano}`}
          ementa={projetoLei.ementa}
          autor={projetoLei.autor}
          image={projetoLei.autor}
          partido={projetoLei.partido}
          teor={projetoLei.teor}
          tema={projetoLei.temas}
          analise={projetoLei.analise}
          impacto={projetoLei.impacto}
          integra={projetoLei.integra}
          status={projetoLei.status}
          dataapresentacao={projetoLei.data_apresentacao}
          datastatus={projetoLei.data_status}
          fase={projetoLei.fase}
          proximaetapa={projetoLei.proxima_etapa}
          pontos={projetoLei.pontos}
          casalegislativa={projetoLei.casa_legislativa}
          key={projetoLei}
        />
        <Projects gridArea='2 / 2 / 2 / 2' />
        {/* <Storage
          gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
          used={25.6}
          total={50}
        /> 
        <Upload
          gridArea={{
            base: '3 / 1 / 4 / 2',
            lg: '1 / 3 / 2 / 4',
          }}
          minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
          pe='20px'
          pb={{ base: '100px', lg: '20px' }}
        />*/}
      </SimpleGrid>
      <Grid
        mb='20px'
        templateColumns={{
          base: '1fr',
          lg: 'repeat(2, 1fr)',
          '2xl': '1.34fr 1.62fr 1fr',
        }}
        templateRows={{
          base: '1fr',
          lg: 'repeat(2, 1fr)',
          '2xl': '1fr',
        }}
        gap={{ base: '20px', xl: '20px' }}
      >
        {/*<Notifications
          used={25.6}
          total={50}
          gridArea={{
            base: '3 / 1 / 4 / 2',
            lg: '2 / 1 / 3 / 3',
            '2xl': '1 / 3 / 2 / 4',
          }}
        />*/}
      </Grid>
    </Box>
  );
}
