import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from 'react-icons/md';

// Public Imports
import Profile from 'views/public/profile';
import Partido from 'views/public/partido';
import Partidos from 'views/public/partidos';
import Parlamentares from 'views/public/parlamentares';
import ProjetosLei from 'views/public/projetosLei';
import ProjetoLei from 'views/public/projetoLei';
import Votacoes from 'views/public/votacoes';
import Votacao from 'views/public/votacao';
import MainDashboard from 'views/public/default';

// Admin Imports
import formProjetoLei from 'views/admin/formProjetoLei';
import formPartido from 'views/admin/formPartido';
import formParlamentar from 'views/admin/formParlamentar';
import formVotacoes from 'views/admin/formVotacoes';
import formUser from 'views/admin/formUser';

// Auth Imports
import SignInCentered from 'views/auth/signIn';

import { getUserAuthenticationRole } from './hooks/authService';
import { TriangleUpIcon } from '@chakra-ui/icons';
const userRole = getUserAuthenticationRole();

const routes = [
  {
    name: 'Painel de Goiânia',
    layout: '/public',
    path: '/default',
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    show: TriangleUpIcon,
  },
  // {
  //   name: 'NFT Marketplace',
  //   layout: '/public',
  //   path: '/nft-marketplace',
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  //   show: true,
  // },
  {
    name: 'Parlamentares',
    layout: '/public',
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: '/parlamentares',
    component: Parlamentares,
    show: true,
  },
  {
    name: 'Projetos de Lei',
    layout: '/public',
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: '/projetosLei',
    component: ProjetosLei,
    show: true,
  },
  {
    name: 'Partidos',
    layout: '/public',
    path: '/partidos',
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    component: Partidos,
    show: true,
  },
  {
    name: 'Votações',
    layout: '/public',
    path: '/votacoes',
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    component: Votacoes,
    show: true,
  },
  {
    name: 'Projeto de Lei',
    layout: '/public',
    path: '/projetoLei/:projetoLeiId',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: ProjetoLei,
    show: false,
  },
  {
    name: 'Parlamentar',
    layout: '/public',
    path: '/parlamentar/:parlamentarId',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
    show: false,
  },
  {
    name: 'Partido',
    layout: '/public',
    path: '/partido/:partidoId',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Partido,
    show: false,
  },
  {
    name: 'Votação',
    layout: '/public',
    path: '/votacao/:votacaoId',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Votacao,
    show: false,
  },
  {
    name: 'Área Restrita',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    show: false,
  },
];

if (userRole === 'admin') {
  routes.push(
    {
      name: 'Novo Projeto',
      layout: '/admin',
      path: '/novoProjetoLei',
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: formProjetoLei,
      show: true,
    },
    {
      name: 'Novo Partido',
      layout: '/admin',
      path: '/novoPartido',
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: formPartido,
      show: true,
    },
    {
      name: 'Novo Parlamentar',
      layout: '/admin',
      path: '/novoParlamentar',
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: formParlamentar,
      show: true,
    },
    {
      name: 'Nova Votação',
      layout: '/admin',
      path: '/novaVotacao',
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: formVotacoes,
      show: true,
    },
    {
      name: 'Votar Projeto pela lista',
      layout: '/admin',
      path: '/novaVotacao/:projetoLeiId',
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: formVotacoes,
      show: false,
    },
    {
      name: 'Editar Projeto',
      layout: '/admin',
      path: '/projetoLei/:projetoLeiId',
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: formProjetoLei,
      show: false,
    },
    {
      name: 'Editar Partido',
      layout: '/admin',
      path: '/partido/:partidoId',
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: formPartido,
      show: false,
    },
    {
      name: 'Editar Parlamentar',
      layout: '/admin',
      path: '/parlamentar/:parlamentarId',
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: formParlamentar,
      show: false,
    },
    {
      name: 'Editar Votação',
      layout: '/admin',
      path: '/votacao/:votacaoId',
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: formVotacoes,
      show: false,
    },
    {
      name: 'Dados do usuário',
      layout: '/admin',
      path: '/usuario/:userId',
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: formUser,
      show: false,
    }
  );
}

export default routes;
