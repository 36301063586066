import React from 'react';

export function ProjectPoints({ pontos }) {
  let cor;

  switch (pontos) {
    case -2:
      cor = 'red';
      break;
    case 2:
      cor = 'green';
      break;
    default:
      cor = '#bebebe';
      break;
  }

  return (
    <div>
      <text
        style={{ color: cor, fontWeight: 700, fontSize: '18px' }}
      >{`${pontos} pts`}</text>
    </div>
  );
}
