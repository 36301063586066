// Chakra imports
import { Box, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import React from 'react';
// Assets
import { ProjectPoints } from './projectPoints';
import { ProjectThumbs } from './projectThumbs';

export default function Project(props) {
  const { title, link, image, projetoUrl, voto, pontos, dados, ...rest } =
    props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const brandColor = useColorModeValue('brand.500', 'white');
  const bg = useColorModeValue('white', 'navy.700');

  return (
    <Card bg={bg} {...rest} p='14px'>
      <Link href={link} target='blank'>
        <Flex align='center' direction={{ base: 'column', md: 'row' }}>
          <ProjectThumbs pontos={pontos} />
          <Box mt={{ base: '10px', md: '0' }}>
            <Text
              color={textColorPrimary}
              fontWeight='700'
              fontSize='md'
              mb='0px'
            >
              {title}
              <span
                style={{
                  fontWeight: '500',
                  color: 'gray',
                  fontSize: '16px',
                  marginLeft: '5px',
                }}
              >
                {dados.temas}
              </span>
            </Text>
            <Flex align='center'>
              <span
                style={{
                  fontWeight: '500',
                  color: 'gray',
                  fontSize: '16px',
                  marginLeft: '0px',
                }}
              >
                {`Impacto: ${voto}`}
              </span>
              <Text></Text>
            </Flex>
          </Box>
        </Flex>
      </Link>
    </Card>
  );
}
