// Chakra imports
import {
  Box,
  Flex,
  // Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import React from 'react';
// Assets
import { ProjectPoints } from './projectPoints';
import { ProjectThumbs } from './projectThumbs';

export default function Project(props) {
  const { parlamentarId, voto, pontos, image, parlamentar, ...rest } = props;

  console.log(`Foto do parlamentar: ${image}`);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const bg = useColorModeValue('white', 'navy.700');
  return (
    <Card bg={bg} {...rest} p='14px'>
      <Link href={`/public/parlamentar/${parlamentarId}`}>
        <Flex align='center' direction={{ base: 'column', md: 'row' }}>
          <Image
            src={image}
            h='65px'
            w='65px'
            mr='8px'
            border='4px solid'
            borderColor='#fefefe'
            borderRadius={150}
          />
          <ProjectThumbs pontos={pontos} tipo='img' />
          <Box mt={{ base: '10px', md: '0' }}>
            <Text
              color={textColorPrimary}
              fontWeight='500'
              fontSize='md'
              mb='0px'
            >
              {parlamentar}
            </Text>
            <Text
              fontWeight='500'
              color={textColorSecondary}
              fontSize='sm'
              me='0px'
            >
              {`Votou ${voto}`}
            </Text>
            {/* <span
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
              }}
            > */}
            <ProjectPoints pontos={pontos} />
            {/* </span> */}
          </Box>
        </Flex>
      </Link>
    </Card>
  );
}
