// Chakra imports
import { Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';
import Project from 'views/public/votacao/components/Project';
// import React, { useEffect, useState } from 'react';
// import axios from '../../../../api/axios';

export default function Projects(props) {
  const { votos } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset'
  );
  return (
    <Card mb={{ base: '0px', '2xl': '20px' }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'
      >
        Votaram à favor
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Abaixo a lista dos parlamentares que votaram à favor da aprovação do
        projeto.
      </Text>
      {votos &&
        votos.map((votoFavoravel) => (
          <Project
            boxShadow={cardShadow}
            mb='20px'
            image={votoFavoravel?.parlamentar?.foto}
            voto={votoFavoravel?.voto}
            parlamentarId={votoFavoravel?.parlamentarId}
            parlamentar={votoFavoravel?.parlamentar?.nome}
            pontos={votoFavoravel?.projeto_lei?.pontos}
          />
        ))}
    </Card>
  );
}
