// Chakra imports
import { Text, useColorModeValue } from '@chakra-ui/react'

// Custom components
import { app } from '../../../../api/feathers'
import axios from '../../../../api/axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Card from 'components/card/Card.js'
import Project from 'views/public/profile/components/Project'
import Voto from './Voto'
import InformationBox from 'views/public/profile/components/InformationBox'
import convertToPoints from '../../../../hooks/convertToPoints'
import { getUserAuthenticationRole } from 'hooks/authService'

export default function Projects(props) {
  const { nome } = props
  const { parlamentarId } = useParams()
  const [votosParlamentar, setVotosParlamentar] = useState({})

  const userRole = getUserAuthenticationRole() || ''

  console.log('userRole: em votacoes', userRole)

  // useEffect(() => {
  //   async function getVotos() {
  //     app
  //       .service('voto')
  //       .find({
  //         query: {
  //           parlamentar_id: parlamentarId,
  //           // $populate: ['parlamentar'], // Especifique o campo relacionado que deseja incluir
  //         },
  //       })
  //       .then((votos) => {
  //         // Montamos o objeto final contendo os votos com os detalhes do parlamentar
  //         const resultadoFinal = votos.map((voto) => {
  //           return {
  //             projeto: voto.projeto_lei.projeto,
  //             ano: voto.projeto_lei.ano,
  //             tipo: voto.projeto_lei.tipo,
  //             voto: voto.voto,
  //             parlamentar: voto.parlamentar.nome, // Nome do parlamentar relacionado
  //             // ... outros campos do parlamentar, se necessário ...
  //           };
  //         });

  //         setVotosParlamentar(resultadoFinal);
  //         console.log('Resultado Final', resultadoFinal);
  //       })
  //       .catch((error) => {
  //         console.error('Erro ao obter os votos do parlamentar:', error);
  //       });

  //     return votosParlamentar;
  //   }

  //   getVotos();
  // }, []);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(`/voto?parlamentar_id=${parlamentarId}&populateDados=true`)
      setVotosParlamentar(request.data)
      return request
    }
    parlamentarId && fetchData()
  }, [parlamentarId])

  console.log('votosParlamentar:', votosParlamentar)

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textColorSecondary = 'gray.400'
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')
  return (
    <Card mb={{ base: '0px', '2xl': '20px' }}>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
        {`Votações de ${nome}`}
      </Text>
      <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        Veja como o parlamentar votou e o impacto dos seus votos no empreendedorismo.
      </Text>

      {votosParlamentar.length > 0 &&
        votosParlamentar.map((item) => {
          // const { casa_legislativa } = item && item.projeto_lei
          // console.log('casa_legislativa', casa_legislativa)
          // if (casa_legislativa === 'Câmara Municipal de Goiânia') {
          if (item.projeto_lei.status !== 'Despublicado' && userRole !== 'admin') {
            return (
              <Voto
                boxShadow={cardShadow}
                mb="20px"
                impacto={item.projeto_lei.impacto}
                voto={item.voto}
                pontos={convertToPoints(item.projeto_lei.impacto)}
                title={`${item.projeto_lei.tipo} ${item.projeto_lei.projeto}/${item.projeto_lei.ano}`}
                temas={item.projeto_lei.temas}
                link={`/public/projetoLei/${item.projeto_lei_id}`}
                key={item.projeto_lei_id}
                status={item.projeto_lei.status}
              />
            )
          }

          if (userRole === 'admin') {
            return (
              <Voto
                boxShadow={cardShadow}
                mb="20px"
                impacto={item.projeto_lei.impacto}
                voto={item.voto}
                pontos={convertToPoints(item.projeto_lei.impacto)}
                title={`${item.projeto_lei.tipo} ${item.projeto_lei.projeto}/${item.projeto_lei.ano}`}
                temas={item.projeto_lei.temas}
                link={`/public/projetoLei/${item.projeto_lei_id}`}
                key={item.projeto_lei_id}
                status={item.projeto_lei.status}
              />
            )
          }
        })}
      {votosParlamentar.length == 0 && (
        <InformationBox boxShadow={cardShadow} mb="20px" message="Não há votações registradas" />
      )}
    </Card>
  )
}
