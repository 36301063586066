import {
  Flex,
  Table,
  Progress,
  Link,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'

// Custom components
import Card from 'components/card/Card'
import Menu from 'components/menu/MainMenu'

// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from 'react-icons/md'
import formatDateDDMMYYYY from 'hooks/formatDateDDMMYYYY'

const columnsData = [
  {
    Header: 'PROJETO',
    accessor: 'projeto_lei_name'
  },
  {
    Header: 'STATUS',
    accessor: 'projeto_lei.status'
  },
  {
    Header: 'DATA',
    accessor: 'data'
  },
  {
    Header: 'VOTAÇÃO',
    accessor: 'performance'
  }
]

export default function ColumnsTable(props) {
  const { dados } = props

  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => dados, [dados])

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } = tableInstance
  initialState.pageSize = 5

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Incentivam o empreendedorismo
        </Text>
        <Menu />
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = ''
                  if (cell.column.Header === 'PROJETO') {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        <Link href={`/public/projetoLei/${row.original.projeto_lei_id}`}>{cell.value}</Link>
                      </Text>
                    )
                  } else if (cell.column.Header === 'STATUS') {
                    data = (
                      <Flex align="center">
                        <Icon
                          w="24px"
                          h="24px"
                          me="5px"
                          color={
                            row.original.votos_favoraveis >= row.original.votos_contrarios
                              ? 'green.500'
                              : row.original.votos_contrarios > row.original.votos_favoraveis
                              ? 'red.500'
                              : cell.value === 'Em Trâmite'
                              ? 'orange.500'
                              : null
                          }
                          as={
                            row.original.votos_favoraveis >= row.original.votos_contrarios
                              ? MdCheckCircle
                              : row.original.votos_contrarios > row.original.votos_favoraveis
                              ? MdCancel
                              : cell.value === 'Em Trâmite'
                              ? MdOutlineError
                              : null
                          }
                        />
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {/* {cell.value} */}
                          {row.original.votos_contrarios > row.original.votos_favoraveis
                            ? 'Rejeitado '
                            : 'Aprovado'}
                        </Text>
                      </Flex>
                    )
                  } else if (cell.column.Header === 'DATA') {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {formatDateDDMMYYYY(cell.value)}
                      </Text>
                    )
                  } else if (cell.column.Header === 'VOTAÇÃO') {
                    data = (
                      <Flex align="center">
                        {/* <Progress
                          variant="table"
                          colorScheme="brandScheme"
                          h="8px"
                          w="108px"
                          value={cell.value}
                        /> */}
                        {row.original.votos_favoraveis}
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          style={{
                            padding: '5px',
                            // background: bgThumb,
                            // marginRight: marginRightSize,
                            // marginLeft: marginLeftSize,
                            borderRadius: 50,
                            color: '#01B574'
                            // marginTop: marginTopSize
                          }}
                        />

                        <FontAwesomeIcon
                          icon={faThumbsDown}
                          style={{
                            padding: '5px',
                            // background: bgThumb,
                            // marginRight: marginRightSize,
                            // marginLeft: marginLeftSize,
                            borderRadius: 50,
                            color: '#EE5D50'
                            // marginTop: marginTopSize
                          }}
                        />
                        {row.original.votos_contrarios}
                      </Flex>
                    )
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: '14px' }}
                      maxH="30px !important"
                      py="8px"
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Card>
  )
}
