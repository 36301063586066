import React, { useState, useEffect } from 'react';
// import { app } from '../../../api/feathers';
import axios from '../../../api/axios';

import { Box, SimpleGrid } from '@chakra-ui/react';
import Votacao from './components/VotacaoTable';

export default function Settings() {
  // const [dados, setData] = useState([]);
  const [votacoes, setVotacoes] = useState([]);

  // useEffect(() => {
  //   app
  //     .service('votacoes')
  //     .find({})
  //     .then((response) => {
  //       setData(response.data);
  //     });
  // }, []);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(`/votacoes`);
      setVotacoes(request.data);
      return request;
    }
    fetchData();
  }, []);

  // console.log('votacoes', votacoes);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb='20px' columns={1} spacing={{ base: '20px', xl: '20px' }}>
        <Votacao
          columnsData={[
            {
              Header: 'Projeto Votado',
              accessor: 'projeto',
            },
            {
              Header: 'Data da Votação',
              accessor: 'data',
            },
            {
              Header: 'Votos favoráveis',
              accessor: 'votos_favoraveis',
            },
            {
              Header: 'Votos Contrários',
              accessor: 'votos_contrarios',
            },
            {
              Header: 'Status',
              accessor: 'status',
            },
            {
              Header: '',
              accessor: 'edit',
            },
          ]}
          tableData={votacoes}
        />
      </SimpleGrid>
    </Box>
  );
}
