// import feathers from '@feathersjs/feathers';
// import auth from '@feathersjs/authentication-client';
// import superagent from 'superagent';
// import history from '../../history';
import feathers from 'feathers-client';
import SERVER_URL from './serverUrl';

const app = feathers();

app.configure(feathers.rest(SERVER_URL).fetch(window.fetch));
// .configure(auth({ storage: window.localStorage }));

const services = {
  users: app.service('users'),
  parlamentar: app.service('parlamentar'),
  partido: app.service('partido'),
  projetoLei: app.service('projeto-lei'),
  comentario: app.service('comentario'),
  voto: app.service('voto'),
};

// app.hooks({
//   error: {
//     all(context) {
//       if (
//         context.path !== 'login' &&
//         context.error &&
//         context.error.name === 'NotAuthenticated'
//       ) {
//         history.push({
//           pathname: `/login`,
//         });
//       }
//       return context;
//     },
//   },
// });

export { app, services };
