// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
// Custom components
import BarChart from 'components/charts/BarChart';
import React from 'react';
import {
  barChartDataConsumption,
  // barChartOptionsConsumption,
} from 'variables/charts';
import { MdBarChart } from 'react-icons/md';

export default function ProjetosTemas(props) {
  const { dados, ...rest } = props;

  let temasUnicos = [];

  function impactosPorTemas(array) {
    const impactos = array.map((objeto) => objeto.impacto);
    const impactosUnicos = Array.from(new Set(impactos)).sort();

    const temas = array.map((objeto) => objeto.temas);
    temasUnicos = Array.from(new Set(temas)).sort();

    const temasFiltradosPorImpacto = impactosUnicos.map((impacto) => {
      const contagemPorTema = temasUnicos.map((tema) => {
        const count = array.filter(
          (obj) => obj.temas === tema && obj.impacto === impacto
        ).length;
        return count;
      });

      return { name: impacto, data: contagemPorTema };
    });

    return temasFiltradosPorImpacto;
  }

  const dadosDoGrafico = impactosPorTemas(dados);
  // console.log('dadosDoGrafico: ', dadosDoGrafico);
  // console.log('temasUnicos: ', temasUnicos);

  const barChartOptionsConsumption = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: '12px',
          fontFamily: undefined,
        },
      },
      theme: 'dark',
    },
    xaxis: {
      categories: temasUnicos,
      show: false,
      labels: {
        show: true,
        style: {
          colors: '#A3AED0',
          fontSize: '12px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: 'black',
      labels: {
        show: true,
        style: {
          colors: '#A3AED0',
          fontSize: '12px',
          fontWeight: '400',
        },
      },
    },

    grid: {
      borderColor: 'rgba(163, 174, 208, 0.3)',
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: 'solid',
      colors: ['#845555', '#f0d7d7', '#55846c', '#d7f0e3', '#f0ebd7'],
    },
    legend: {
      show: true,
    },
    colors: ['#845555', '#f0d7d7', '#55846c', '#d7f0e3', '#f0ebd7'],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        columnWidth: '25px',
        columnHeight: '25px',
        horizontal: true,
      },
    },
  };

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' }
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  );
  return (
    <Card align='center' direction='column' w='100%' {...rest}>
      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'
        >
          Projetos por Temas
        </Text>
        {/* <Button
          align='center'
          justifyContent='center'
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w='37px'
          h='37px'
          lineHeight='100%'
          borderRadius='10px'
          {...rest}
        > 
          <Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
        </Button>*/}
      </Flex>
      <Text
        me='auto'
        color='#A3AED0'
        fontSize='md'
        fontWeight='500'
        lineHeight='100%'
        px='15px'
      >
        Impacto no Empreendedorismo por tema
      </Text>
      <Box h='340px' mt='auto'>
        <BarChart
          chartData={dadosDoGrafico}
          chartOptions={barChartOptionsConsumption}
          key={dadosDoGrafico}
        />
      </Box>
    </Card>
  );
}
