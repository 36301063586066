/* eslint-disable */
import {
  Button,
  Flex,
  Link,
  Progress,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import ProjectThumbs from './projectThumbs';
import React, { useMemo } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { getUserAuthenticationRole } from 'hooks/authService';
const userRole = getUserAuthenticationRole();

export default function ProjetosLei(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex, pageSize },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = tableInstance;
  initialState.pageSize = 150;

  const convertPontos = (impacto) => {
    if (impacto === 'Contra' || impacto === 'Contra com Ressalvas') {
      return '-1';
    }
    if (impacto === 'Favorável' || impacto === 'Favorável com Ressalvas') {
      return '1';
    }
    return '0';
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'
        >
          Projetos de Lei
        </Text>
        <Menu />
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            // console.log('row', row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Link href={`/public/projetoLei/${row.original.id}`}>
                    <Tooltip
                      label={`Impacto ${row.original.impacto}`}
                      aria-label='Impacto'
                    >
                      <SimpleGrid columns={2}>
                        <Flex alignItems='center'>
                          <ProjectThumbs pontos={row.original.pontos} />
                          <Flex flexDirection='column'>
                            <Text
                              color={textColor}
                              fontSize='sm'
                              fontWeight='700'
                              alignItems='center'
                            >
                              <span style={{ textWrap: 'nowrap' }}>
                                {`${row.original.tipo} ${row.original.projeto}/${row.original.ano}`}
                              </span>
                            </Text>
                            <Text>{row.original.temas}</Text>
                            <Text>{row.original.impacto}</Text>
                          </Flex>
                        </Flex>
                      </SimpleGrid>
                    </Tooltip>
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Flex align='center'>
                    <Text
                      me='10px'
                      color={textColor}
                      fontSize='sm'
                      fontWeight='400'
                    >
                      {row.original.ementa}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Text color={textColor} fontSize='sm' fontWeight='400'>
                    {row.original.autor}
                  </Text>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Text color={textColor} fontSize='sm' fontWeight='400'>
                    {row.original.relator}
                  </Text>
                </Td>
                {/* <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Text color={textColor} fontSize='sm' fontWeight='400'>
                    {row.original.temas}
                  </Text>
                </Td> */}

                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor='transparent'
                >
                  <Text color={textColor} fontSize='sm' textAlign='left'>
                    {row.original.status}
                  </Text>
                </Td>
                {userRole === 'admin' && (
                  <Td
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    borderColor='transparent'
                  >
                    <Text color={textColor} fontSize='sm' fontWeight='300'>
                      <Link href={`/admin/projetoLei/${row.original.id}`}>
                        <span
                          style={{
                            padding: '2px 10px',
                            borderRadius: '50px',
                            background: '#eaeaea',
                            fontSize: '12px',
                            color: '#8f9bba',
                          }}
                        >
                          Editar
                        </span>
                      </Link>
                    </Text>
                    <Text color={textColor} fontSize='sm' fontWeight='300'>
                      <Link
                        href={`/admin/projetoLei/novaVotacao?projetoLeiId=${row.original.id}`}
                      >
                        <span
                          style={{
                            padding: '2px 10px',
                            borderRadius: '50px',
                            background: '#eaeaea',
                            fontSize: '12px',
                            color: '#8f9bba',
                          }}
                        >
                          Votar
                        </span>
                      </Link>
                    </Text>
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          size='sm'
        >
          Anterior
        </Button>
        <Text fontSize='sm'>
          Página{' '}
          <strong>
            {pageIndex + 1} de {Math.ceil(data.length / pageSize)}
          </strong>
        </Text>
        <Button onClick={() => nextPage()} disabled={!canNextPage} size='sm'>
          Próxima
        </Button>
      </Flex>
    </Card>
  );
}
