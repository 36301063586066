// import React from 'react';

// export function ProjectPoints({ pontos }) {
//   let cor;

//   switch (pontos) {
//     case '-1':
//       cor = 'red';
//       break;
//     case '1':
//       cor = 'green';
//       break;
//     default:
//       cor = '#bebebe';
//       break;
//   }

//   return (
//     <div>
//       <text
//         style={{ color: cor, fontWeight: 700, fontSize: '18px' }}
//       >{`${pontos} pts`}</text>
//     </div>
//   );
// }

import React from 'react';

export function ProjectPoints(props) {
  const {
    dados: { voto },
    pontos,
  } = props;

  // console.log(`Ponto da votação: ${pontos} - voto: ${voto}`);

  // switch (pontos) {
  //   case '2':
  //     cor = 'green';
  //     break;
  //   case '-2':
  //     cor = 'red';
  //     break;
  //   default:
  //     cor = '#837554';
  //     break;
  // }

  let ponto = '0 pt';
  let cor = '#837554';

  if (
    (voto === 'favoravel' && pontos === 2) ||
    (voto === 'contra' && pontos === -2)
  ) {
    cor = 'green';
    ponto = ' 1 pts';
  }
  if (
    (voto === 'favoravel' && pontos === -2) ||
    (voto === 'contra' && pontos === 2)
  ) {
    cor = 'red';
    ponto = ' -1 pts';
  }

  return (
    <div>
      <text style={{ color: cor, fontWeight: '700', marginRight: '4px' }}>
        {`Votou ${voto === 'favoravel' ? 'favorável' : 'contra'},  ${ponto}`}
      </text>
    </div>
  );
}
