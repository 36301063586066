// Chakra imports
import { Box, Divider, Image, Flex, Text, Link, useColorModeValue, SimpleGrid } from '@chakra-ui/react'
import Card from 'components/card/Card.js'
import Information from 'views/public/profile/components/Information'
import React from 'react'
import SocialNetworkUsername from './cleanUsernames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faYoutube, faFacebook, faTelegram, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons'
import { column } from 'stylis'

export default function Banner(props) {
  const {
    banner,
    avatar,
    name,
    job,
    twitter,
    youtube,
    instagram,
    bio,
    endereco,
    ranking,
    partido,
    gabinete,
    email,
    telefones,
    nascimento
  } = props
  // Chakra Color Mode

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textColorSecondary = 'gray.400'
  const borderColor = useColorModeValue('white !important', '#111C44 !important')
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')
  return (
    <Card mb={{ base: '0px', lg: '20px' }} align="center">
      <Box bg={`url(${banner})`} bgSize="cover" borderRadius="6px" h="131px" w="100%" />

      <Image
        // ml="80px"
        mx="auto"
        src={avatar}
        h="190px"
        w="190px"
        mt="-100px"
        border="4px solid"
        borderColor={borderColor}
        borderRadius={150}
        boxSize="190px"
        alt={name}
        objectFit="cover"
        objectPosition="top"
      />
      <SimpleGrid columns={{ base: 1, '2xl': 2 }}>
        <Flex direction="column">
          <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
            {name}
          </Text>
          <Text color={textColorPrimary} fontSize="md">
            {job}
          </Text>
          <Text color={textColorSecondary} fontSize="md">
            {partido}
          </Text>
          <Flex w="max-content" mx="auto" mt="26px">
            {twitter && (
              <Flex mx="auto" me="60px" align="center" direction="column">
                <Link href={twitter}>
                  <FontAwesomeIcon
                    icon={faTwitter}
                    style={{
                      color: '#ffffff',
                      background: '#00acee',
                      padding: 8,
                      borderRadius: 50
                    }}
                  />
                  <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                    <SocialNetworkUsername url={twitter} />
                  </Text>
                </Link>
              </Flex>
            )}
            {youtube && (
              <Flex mx="auto" me="60px" align="center" direction="column">
                <Link href={youtube}>
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{
                      color: '#ffffff',
                      background: '#cc0000',
                      padding: 8,
                      borderRadius: 50
                    }}
                  />
                  <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                    <SocialNetworkUsername url={youtube} />
                  </Text>
                </Link>
              </Flex>
            )}
            {instagram && (
              <Flex mx="auto" align="center" direction="column">
                <Link href={instagram}>
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{
                      color: '#ffffff',
                      background: '#e1306c',
                      padding: 8,
                      borderRadius: 50
                    }}
                  />
                  <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                    <SocialNetworkUsername url={instagram} />
                  </Text>
                </Link>
              </Flex>
            )}
          </Flex>
          <Flex direction="column" pl="20px">
            {email && (
              <Flex align="center" direction="row" gap="7px" mt="20px" mb="10px">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{
                    color: '#ffffff',
                    background: '#aaaaaa',
                    padding: 5,
                    borderRadius: 50
                  }}
                  size="2xs"
                />
                <Link href={`mailto:${email}`}>
                  <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                    {email}
                  </Text>
                </Link>
              </Flex>
            )}
            {telefones && (
              <Flex align="center" direction="row" gap="7px" mb="10px">
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{
                    color: '#ffffff',
                    background: '#aaaaaa',
                    padding: 5,
                    borderRadius: 50
                  }}
                  size="2xs"
                />
                <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                  {telefones}
                </Text>
              </Flex>
            )}
            <Flex align="center" direction="row" gap="7px">
              <FontAwesomeIcon
                icon={faHome}
                style={{
                  color: '#ffffff',
                  background: '#aaaaaa',
                  padding: 5,
                  borderRadius: 50
                }}
                size="2xs"
              />
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                {`${endereco}. Gabinete ${gabinete}`}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Text color={textColorSecondary} fontSize="md" me="26px" my="20px" textAlign="justify">
          {bio}
        </Text>
      </SimpleGrid>

      {/* <Flex>
        <SimpleGrid columns={2} gap={5} mt={5} justifyContent='space-between'>
          <Information boxShadow={cardShadow} title='Email' value={email} />
          <Information
            boxShadow={cardShadow}
            title='Telefones'
            value={telefones}
          />
        </SimpleGrid>
      </Flex> */}
    </Card>
  )
}
