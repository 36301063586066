// Chakra imports
import { Text, useColorModeValue } from '@chakra-ui/react'

// Custom components
import Card from 'components/card/Card.js'
import React, { useEffect, useState } from 'react'
import Project from 'views/public/profile/components/Project'
import ProjetoAutoria from 'views/public/profile/components/ProjetoAutoria'
import InformationBox from 'views/public/profile/components/InformationBox'
import axios from '../../../../api/axios'
import convertToPoints from '../../../../hooks/convertToPoints'
import { getUserAuthenticationRole } from 'hooks/authService'

export default function Projects(props) {
  const { nome } = props
  const [autoriaProjetos, setAutoriaProjetos] = useState({})

  const userRole = getUserAuthenticationRole() || ''

  const url_query = `/projeto-lei?autor=${nome}${userRole !== 'admin' ? '&status[$ne]=Despublicado' : ''}`

  useEffect(() => {
    async function getAutoriaProjetosLei() {
      // const request = await axios.get(`/projeto-lei?autor=${nome}`)
      const request = await axios.get(url_query)
      setAutoriaProjetos(request.data.data)
      return request
    }
    getAutoriaProjetosLei()
  }, [])

  // console.log('autoriaProjetos', autoriaProjetos);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textColorSecondary = 'gray.400'
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')
  return (
    <Card mb={{ base: '0px', '2xl': '20px' }}>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
        {`Autorias de ${nome}`}
      </Text>
      <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        Veja os projetos de lei de autoria do parlamentar e qual o seu impacto no empreendedorismo.
      </Text>
      {autoriaProjetos.length > 0 &&
        autoriaProjetos.map((item) => (
          <ProjetoAutoria
            boxShadow={cardShadow}
            mb="20px"
            voto={item.impacto}
            pontos={convertToPoints(item.impacto)}
            title={`${item.tipo} ${item.projeto}/${item.ano}`}
            dados={item}
            link={`/public/projetoLei/${item.id}`}
            key={item}
          />
        ))}
      {autoriaProjetos.length == 0 && (
        <InformationBox
          boxShadow={cardShadow}
          mb="20px"
          message="Não há projetos de autoria do parlamentar"
        />
      )}
    </Card>
  )
}
