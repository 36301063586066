// Chakra imports
import {
  Box,
  Flex,
  // Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import React from 'react';
// Assets
import { ProjectPoints } from './projectPoints';
import ProjectThumbs from './projectThumbs';

export default function Project(props) {
  const {
    parlamentar,
    parlamentarId,
    image,
    projetoUrl,
    voto,
    dados,
    pontos,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const bg = useColorModeValue('white', 'navy.700');

  return (
    <Card bg={bg} {...rest} p='14px'>
      <Link href={`/public/parlamentar/${parlamentarId}`}>
        <Flex align='center' direction={{ base: 'column', md: 'row' }}>
          <Image
            src={image}
            border='4px solid'
            boxSize='65px'
            objectFit='cover'
            objectPosition='top'
            mr='8px'
            borderColor='#fefefe'
            borderRadius={150}
          />

          <ProjectThumbs pontos={pontos} voto={voto} tipo='img' />
          <Box mt={{ base: '10px', md: '0' }}>
            <Text
              color={textColorPrimary}
              fontWeight='500'
              fontSize='md'
              mb='0px'
            >
              {parlamentar}
            </Text>

            <ProjectPoints pontos={pontos} dados={dados} />
          </Box>
        </Flex>
      </Link>
    </Card>
  );
}
