import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from '../../../api/axios'
import { app } from '../../../api/feathers'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  FormControl,
  FormLabel,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useColorModeValue
} from '@chakra-ui/react'
import Card from '../../../components/card/Card'
import { Icon } from '@chakra-ui/react'
import { NotAllowedIcon, Add, AddIcon } from '@chakra-ui/icons'
import formatDate from 'hooks/formatDate'

export default function Settings() {
  const { projetoLeiId } = useParams()
  const textColor = useColorModeValue('navy.700', 'white')

  const [formData, setFormData] = useState({
    tipo: '',
    projeto: '',
    // data_apresentacao: '',
    ano: '',
    ementa: '',
    analise: '',
    autor: '',
    partido: '',
    relator: '',
    temas: '',
    integra: '',
    impacto: '',
    status: '',
    // data_status: '',
    // teor: '',
    fase: '',
    proxima_etapa: '',
    casa_legislativa: '',
    uf: 'GO',
    municipio: 'Goiânia'
  })

  const [partidoList, setPartidoList] = useState([])

  const [parlamentarList, setParlamentarList] = useState([])
  useEffect(() => {
    app
      .service('parlamentar')
      .find({
        query: {
          $limit: 100
        }
      })
      .then((response) => {
        const parlamentarListData = response?.data.map((parlamentar) => ({
          id: parlamentar.id,
          nome: parlamentar.nome
        }))
        const parlamentarListSorted = parlamentarListData.sort((a, b) => a.nome.localeCompare(b.nome))
        // console.log('parlamentarListSorted', parlamentarListSorted);
        setParlamentarList(parlamentarListSorted)
      })
  }, [])

  const [isDataExists, setIsDataExists] = useState(false)
  const [sendAlert, setSendAlert] = useState('')

  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(`/projeto-lei/${projetoLeiId}`)
      // console.log('request.data projetoLei', request.data);
      setFormData(request.data)
      return request
    }

    if (projetoLeiId) {
      fetchData()
    }
  }, [projetoLeiId])

  useEffect(() => {
    async function fetchPartidos() {
      const request = await axios.get('/partido')
      // console.log('request.data', request.data);
      const { data: requestData } = request
      // console.log('requestData', requestData.data);

      const partidoListData = requestData?.data.map((partido) => ({
        id: partido.id,
        nome: partido.nome,
        sigla: partido.sigla
      }))
      const partidoListSorted = partidoListData.sort((a, b) => a.nome.localeCompare(b.nome))
      // console.log('partidoListSorted', partidoListSorted);
      setPartidoList(partidoListSorted)
      return request
    }

    fetchPartidos()
  }, [])

  const checkDataExists = async (value) => {
    try {
      const response = await axios.get(`/projeto-lei?ano=${formData?.ano}&projeto=${value}`)
      setIsDataExists(response.data.total > 0)
    } catch (error) {
      console.error(error)
    }
  }

  const handleProjectNumberChange = (event) => {
    const { value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      projeto: value
    }))

    !projetoLeiId && checkDataExists(value)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))

    if (name === 'impacto') {
      let ponto = 0 // Valor padrão

      switch (value) {
        case 'Favorável':
          ponto = 2
          break
        case 'Favorável com ressalvas':
          ponto = 1
          break
        case 'Contra com ressalvas':
          ponto = -1
          break
        case 'Contrário':
          ponto = -2
          break

        default:
          break
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        pontos: ponto
      }))
    }
  }

  // const handleYearChange = (event) => {
  //   const { value } = event.target;
  //   const year = value.slice(0, 4);
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     ano: year,
  //     data_apresentacao: value,
  //   }));
  // };

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (projetoLeiId) {
      try {
        const response = await axios.patch(`/projeto-lei/${projetoLeiId}`, formData)
        console.log('Dados atualizados com sucesso!', response.data)
      } catch (error) {
        console.error('Ocorreu um erro ao enviar os dados:', error)
      }
    } else {
      console.log('isDataExists: ', isDataExists)
      if (!isDataExists) {
        try {
          const response = await axios.post('/projeto-lei', formData)
          console.log('Dados enviados com sucesso!', response.data)
          setSendAlert('success')
        } catch (error) {
          console.error('Ocorreu um erro ao enviar os dados:', error)
          setSendAlert('error')
        }
      }
    }
    // console.log(formData);
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
        <Card>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Tipo de Projeto
              </FormLabel>
              <Select
                name="tipo"
                onChange={handleChange}
                value={formData.tipo}
                isRequired={true}
                {...(projetoLeiId ? { variant: 'filled' } : { variant: 'auth' })}
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Selecione o tipo"
                mb="24px"
                fontWeight="500"
                size="md"
                {...(projetoLeiId && { isDisabled: true })}
              >
                <option value="PL">PL - Projeto de Lei</option>
                <option value="PLE">PLE - Projeto de Lei do Executivo</option>
                <option value="PLC">PLC - Projeto de Lei Complementar</option>
                <option value="PLCE">PLCE - Projeto de Lei Complementar do Executivo</option>
                <option value="PLCC">PLCC - Projeto de Lei Complementar da Câmara</option>
              </Select>
              {/* <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Data de Apresentação
              </FormLabel>
              <Input
                onChange={handleYearChange}
                name='data_apresentacao'
                value={formatDate(formData.data_apresentacao)}
                {...(isDataExists && { isInvalid: true })}
                {...(projetoLeiId
                  ? { variant: 'filled' }
                  : { variant: 'auth' })}
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='date'
                mb='24px'
                fontWeight='500'
                size='md'
                {...(projetoLeiId && { isDisabled: true })}
              /> */}
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Ano da Apresentação
              </FormLabel>
              <Select
                onChange={handleChange}
                name="ano"
                value={formData.ano}
                placeholder="Selecione o ano"
                isRequired={true}
                {...(isDataExists && { isInvalid: true })}
                {...(projetoLeiId ? { variant: 'filled' } : { variant: 'auth' })}
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                mb="24px"
                fontWeight="500"
                size="md"
                {...(projetoLeiId && { isDisabled: true })}
              >
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
              </Select>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Número do Projeto{' '}
                {isDataExists && (
                  <span style={{ padding: '0px 20px', color: '#cc6666' }}>
                    {`Projeto ${formData?.projeto}/${formData?.ano} já cadastrado.`}
                  </span>
                )}
              </FormLabel>
              <InputGroup>
                <Input
                  value={formData.projeto}
                  name="projeto"
                  onChange={handleProjectNumberChange}
                  onBlur={handleProjectNumberChange}
                  isRequired={true}
                  {...(projetoLeiId ? { variant: 'filled' } : { variant: 'auth' })}
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="1234"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                  {...(projetoLeiId && { isDisabled: true })}
                />

                {isDataExists && (
                  <InputLeftElement>
                    <NotAllowedIcon boxSize={6} ml="10px" color="red.500" />
                  </InputLeftElement>
                )}
              </InputGroup>

              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Ementa
              </FormLabel>
              <Textarea
                name="ementa"
                onChange={handleChange}
                value={formData.ementa}
                isRequired={true}
                variant="outline"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="ementa"
                placeholder="Informe a ementa do projeto"
                mb="24px"
                fontWeight="500"
                size="md"
              />
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Análise
              </FormLabel>
              <Textarea
                name="analise"
                onChange={handleChange}
                value={formData.analise}
                isRequired={true}
                variant="outline"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Informe a análise do projeto."
                mb="24px"
                fontWeight="500"
                size="md"
              />
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Autor
              </FormLabel>
              <Select
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                name="autor"
                onChange={handleChange}
                value={formData.autor}
                placeholder="Selecione o Autor do Projeto"
                mb="24px"
                fontWeight="500"
                size="md"
              >
                {parlamentarList.map((parlamentar) => (
                  <option value={parlamentar.nome}>{parlamentar.nome}</option>
                ))}
              </Select>
              <Button
                color="#8f9bba"
                size="xs"
                fontWeight="400"
                mt="-10"
                onClick={() => {
                  history.push('/admin/novoParlamentar')
                }}
              >
                <AddIcon size="xs" mr="7px" />
                Adicionar um novo Parlamentar
              </Button>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Partido do Autor na data de apresentação
              </FormLabel>
              <Select
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Selecione o Partido"
                mb="24px"
                fontWeight="500"
                size="md"
                value={formData.partido}
                onChange={handleChange}
                name="partido"
              >
                {partidoList.map((partido) => (
                  <option key={partido.id} value={partido.sigla}>
                    {partido.sigla}
                    {' - '}
                    {partido.nome}
                  </option>
                ))}
              </Select>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Relator
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                name="relator"
                onChange={handleChange}
                value={formData.relator}
                placeholder="Nome do Relator do Projeto"
                mb="24px"
                fontWeight="500"
                size="md"
              />

              {/* <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Teor
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                name='teor'
                onChange={handleChange}
                value={formData.teor}
                type='teor'
                placeholder='Regulatório, Informação, Saúde...'
                mb='24px'
                fontWeight='500'
                size='md'
              /> */}
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Tema
              </FormLabel>
              <Select
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                name="temas"
                onChange={handleChange}
                value={formData.temas}
                placeholder="Selecione o tema"
                mb="24px"
                fontWeight="500"
                size="md"
              >
                <option value="Acessibilidade">Acessibilidade</option>
                <option value="Administrativo">Administrativo</option>
                <option value="Comércio">Comércio</option>
                <option value="Consumidor">Consumidor</option>
                <option value="Empreendedorismo">Empreendedorismo</option>
                <option value="Funcionamento Bancário">Funcionamento Bancário</option>
                <option value="Indústria">Indústria</option>
                <option value="Mobilidade">Mobilidade</option>
                <option value="Saúde">Saúde</option>
                <option value="Segurança">Segurança</option>
                <option value="Tecnologia e Pesquisa">Tecnologia e Pesquisa</option>
                <option value="Trabalhista">Trabalhista</option>
                <option value="Tributário">Tributário</option>
                <option value="Outros">Outros</option>
              </Select>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                URL do arquivo do projeto na íntegra
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                name="integra"
                onChange={handleChange}
                value={formData.integra}
                placeholder="URL do Projeto de Lei"
                mb="24px"
                fontWeight="500"
                size="md"
              />
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Impacto no empreendedorismo
              </FormLabel>
              <Select
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                name="impacto"
                onChange={handleChange}
                value={formData.impacto}
                placeholder="Selecione o impacto"
                mb="24px"
                fontWeight="500"
                size="md"
              >
                <option value="Favorável">Favorável</option>
                <option value="Favorável com ressalvas">Favorável com ressalvas</option>
                <option value="Neutro">Neutro</option>
                <option value="Contra com ressalvas">Contra com ressalvas</option>
                <option value="Contrário">Contrário</option>
              </Select>

              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Status
              </FormLabel>
              <Select
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                name="status"
                onChange={handleChange}
                value={formData.status}
                placeholder="Informe o status do projeto"
                mb="24px"
                fontWeight="500"
                size="md"
              >
                <option value="Em Trâmite">Em Trâmite</option>
                <option value="Aprovado">Aprovado</option>
                <option value="Rejeitado">Rejeitado</option>
                <option value="Arquivado">Arquivado</option>
                <option value="Despublicado">Despublicado</option>
              </Select>
              {/* <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
              >
                Data do Status
              </FormLabel>
              <Input
                variant='auth'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                name='data_status'
                onChange={handleChange}
                value={formatDate(formData.data_status)}
                type='date'
                placeholder='Informe data do status'
                mb='24px'
                fontWeight='500'
                size='md'
              /> */}
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Fase
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                name="fase"
                onChange={handleChange}
                value={formData.fase}
                type="fase"
                placeholder="Se necessário, informe a fase do projeto"
                mb="24px"
                fontWeight="500"
                size="md"
              />
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Próxima Etapa
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                name="proxima_etapa"
                onChange={handleChange}
                value={formData.proxima_etapa}
                type="proxima_etapa"
                placeholder="Se necessário, qual a próxima etapa para esse projeto?"
                mb="24px"
                fontWeight="500"
                size="md"
              />
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Casa legislativa
              </FormLabel>
              <RadioGroup
                name="casa_legislativa"
                value={formData.casa_legislativa}
                onChange={(value) => handleChange({ target: { name: 'casa_legislativa', value } })}
              >
                <Stack direction="row">
                  <Radio value="Assembleia Legislativa de Goiás" size="sm">
                    Assembleia Legislativa de Goiás
                  </Radio>
                  <Radio value="Câmara Municipal de Goiânia" size="sm">
                    Câmara Municipal de Goiânia
                  </Radio>
                </Stack>
              </RadioGroup>
              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                mt="24px"
              >
                {projetoLeiId ? 'Atualizar Projeto de Lei' : 'Adicionar Projeto de Lei'}
              </Button>
            </FormControl>
          </form>

          {sendAlert === 'success' && (
            <Alert status="success">
              <AlertIcon />
              <AlertTitle>Sucesso!</AlertTitle>
              <AlertDescription>Dados enviados com sucesso!</AlertDescription>
            </Alert>
          )}
          {sendAlert === 'error' ||
            (isDataExists && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Erro!</AlertTitle>
                <AlertDescription>Verifique os dados digitados e tente novamente.</AlertDescription>
              </Alert>
            ))}
        </Card>
      </SimpleGrid>
    </Box>
  )
}
